/** @jsx jsx */
import React, { useContext, useRef, useState } from "react";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import { sideBarCss } from "./easycoach-sider.styles";

import { BiHomeAlt } from "react-icons/bi";
import { contentPages, themeColorPalettes } from "../../utils/utilities";
import AppContext from "../../utils/context";
import { jsx } from "@emotion/react";

const { Sider } = Layout;

interface ISideBarProps {
  setContentForm: (arg: number) => void;
  setCollapsed: (arg: boolean) => void;
  theme: string;
  collapse: boolean;
}

const EasyCoachSideBar: React.FC<ISideBarProps> = ({
  setContentForm,
  setCollapsed,
  theme,
  collapse,
}) => {
  const [selected, setSelected] = useState("6");
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const onCollapse = (collapse: boolean) => {
    setCollapsed(collapse);
  };

  const toggle = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setCollapsed(!collapse);
  };

  const CustomNo = ({ booking }: any) => (
    <div
      style={{
        backgroundColor: themeColorPalettes[theme].iconColor,
        borderRadius: "10%",
        padding: "5px 10px",
        margin: "0 15px 0 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontSize: "14px",
          color: themeColorPalettes[theme].sideBarColor,
          fontWeight: "bolder",
        }}
      >
        {booking}
      </span>
    </div>
  );
  const CustomLabel = ({ label }: any) => (
      <span
        style={{
          fontSize: "14px",
          color: `${
            theme === "Sierra"
              ? themeColorPalettes[theme].textColor
              : themeColorPalettes[theme].iconColor
          }`,
          fontWeight: "bolder",
        }}
      >
        {label}
      </span>
  );
  return (
    <div 
      css={sideBarCss}
    >
      <Sider
        collapsible
        collapsed={collapse}
        onCollapse={onCollapse}
        trigger={null}
        style={{
          backgroundColor: themeColorPalettes[theme].sideBarColor,
          height: "100vh",
        }}
      >
          <Menu
            theme="dark"
            defaultSelectedKeys={["sub4"]}
            defaultOpenKeys={[
              `${selected === "4" || selected === "5" ? "sub4" : ""}`,
            ]}
            mode="inline"
            style={{
              backgroundColor: themeColorPalettes[theme].sideBarColor,
              color: themeColorPalettes[theme].iconColor,
            }}
            onSelect={({ key }) => {
              setSelected(key);
              if (key === "11") {
                setContentForm(contentPages["paidBookings"]);
              }
              if (key === "10") {
                setContentForm(contentPages["paidNoTickets"]);
              }
              if (key === "3") {
                setContentForm(contentPages["unconfirmedBookings"]);
              }
              if (key === "13") {
                setContentForm(contentPages["easyCoachLanding"]);
              }
            }}
            items={[
              {
                key: "13",
                icon: <BiHomeAlt />,
                label: <CustomLabel label={"Home"} />,
              },
              {
                key: "11",
                icon: <CustomNo booking={context.bookingsCount.confirmed} />,
                label: <CustomLabel label={"Paid"} />,
              },
              {
                key: "10",
                icon: (
                  <CustomNo booking={context.bookingsCount.confirmed_no_ticket} />
                ),
                label: <CustomLabel label={"No Ticket"} />,
              },
              {
                key: "3",
                icon: <CustomNo booking={context.bookingsCount.not_confirmed} />,
                label: <CustomLabel label={"Unconfirmed"} />,
              },
            ]}
          ></Menu>
        {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: `trigger ${!collapse ? "" : "trigger-marginLeft-shrink"}`,
          onClick: toggle,
        })}
      </Sider>
    </div>
  );
};

export default EasyCoachSideBar;
