/** @jsx jsx */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Table } from "antd";
import { paidBookingsNoTicketColumnData } from "../../utils/initialData";

import Axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Filters from "../filters/filters.component";
import { useProfileAxios } from "../../utils/customHooks";
import AppContext from "../../utils/context";

import { jsx } from "@emotion/react";
import { paidBookingsCss } from "../paid-bookings/paid-bookings.styles";
import { CSVLink } from "react-csv";
import { themeColorPalettes } from "../../utils/utilities";

export type IThemeProps = {
  theme: string;
};

const YesterdayBookings: React.FC<IThemeProps> = ({ theme }) => {
  const context = useContext(AppContext);
  const [columns, setColumns] = useState<any>([]);
  const [paidTickets, setPaidTickets] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [paginationMetaData, setPaginationMetaData] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [nextRolePage, setNextRolePage] = useState(1);
  const [paginationType, setPaginationType] = useState("normal");

  const navigate = useNavigate();
  const { innerWidth, innerHeight } = context.windowSize;

  const { state: profileState } = useProfileAxios(
    `${process.env.REACT_APP_USER_URL}/users/profile`
  );

  useEffect(() => {
    if (profileState.status === "fetched") {
      console.log("profile fetched", profileState.data);
    } else if (profileState.status === "fetching") {
      console.log("profile fetching", profileState.data);
    } else if (profileState.status === "idle") {
      console.log("profile idle", profileState.data);
    } else {
      console.log("profile error", profileState.data);
      navigate("/login");
    }
  }, [profileState, navigate]);

  const fetchAllBookings = useCallback(async () => {
    await Axios.get(
      `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/ticket/unsent?is_customer_care=is_customer_care&yesterday=yesterday&page=${nextRolePage}`,
      {
        headers: {
          Authorization: "Bearer " + context.userDetails.data.token
        },
      }
    )
      .then((data) => {
        let resBody = data.data.data.data;
        console.log(resBody, "all bookings fetched");
        let parsedArray = resBody.rows.map(
          (ticketHolder: any, idx: Number) => {
            let bookingDate =  ticketHolder.created_at.split('T')[0].split('-').reverse().join('/')
            return { 
              ...ticketHolder, 
              key: idx,
              booking_date: bookingDate,
            };
          }
        );
        if (context.profileInfo.assigned_role === "trips") {
          parsedArray = parsedArray.map(((ticket: any, idx: number) => {
            return {
              key: idx,
              booking_date: ticket.booking_date,
              name: ticket.name,
              phone: ticket.phone,
              id: ticket.id,
              trade_id: ticket.trade_id,
              source: ticket.source,
              destination: ticket.destination,
              departure_date: ticket.departure_date,
              total_amount: ticket.total_amount
            }
          }))
          setCsvData(parsedArray)
         
        } else {
        }
        console.log(parsedArray, "parsedArray");
        setPaginationMetaData(resBody.paginationMeta.totalPages);
        setPaidTickets(parsedArray);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response, "error fetching all bookings");
        toast.error(error.response.data.detail, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: 5000,
        });
      });
  }, [nextRolePage]);

  useEffect(() => {
    if (nextRolePage >= 1 && !searchQuery) {
      fetchAllBookings();
    }
    return () => {};
  }, [nextRolePage, searchQuery, fetchAllBookings]);

  useEffect(() => {
    let columnInfo = [];
    if (
      context.profileInfo.assigned_role === "trips"
    ) {
      columnInfo = paidBookingsNoTicketColumnData.filter(
        (item: any, idx: number) => item.key !== "operator"
      );
      columnInfo = columnInfo.filter(
        (item: any, idx: number) => item.key !== "sms"
      );
      columnInfo = columnInfo.filter(
        (item: any, idx: number) => item.key !== "booking_channel"
      );
      columnInfo.unshift({
        title: "Booking Date",
        dataIndex: "booking_date",
        key: "booking_date",
        width: 50,
        fixed: "left",
        ellipsis: true
      });
    } else {
      columnInfo = paidBookingsNoTicketColumnData;
    }
    setColumns([...columnInfo]);
  }, [context.profileInfo.assigned_role]);
  
  return (
    <div css={paidBookingsCss}>
      <Filters
        theme={theme}
        setLoading={setLoading}
        nextPageNumber={nextPageNumber}
        loading={loading}
        searchQuery={searchQuery}
        setNextRolePage={setNextRolePage}
        setSearchQuery={setSearchQuery}
        setPaidTickets={setPaidTickets}
        setPaginationMetaData={setPaginationMetaData}
        setPaginationType={setPaginationType}
      />
      {paginationMetaData ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative'
          }}
        >
        <Table
          columns={columns}
          dataSource={paidTickets}
          loading={loading}
          virtual
          scroll={{
            x: innerWidth * 0.8,
            y:
              innerHeight > 750
                ? innerHeight * 0.7
                : innerHeight > 500
                ? innerHeight * 0.6
                : innerHeight * 0.4,
          }}
          pagination={{
            onChange: (page) => {
              if (paginationType === "search") {
                setNextPageNumber(page);
              } else {
                setNextRolePage(page);
              }
            },
            total: paginationMetaData * 10 - 1,
          }}
        />
        <CSVLink 
          data={csvData}
          filename={"yesterday-bookings.csv"}
          style={{
            backgroundColor:
              theme === "Sierra"
                ? themeColorPalettes[theme].sideBarColor
                : themeColorPalettes[theme].extraColor,
            color:
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor,
            padding: '10px 20px',
            position: 'absolute',
            borderRadius: '5px',
            bottom: '-50px',
            right: '40px'
          }}>Export</CSVLink>
        </div>
      ) : (
        "loading..."
      )}
    </div>
  );
};

export default YesterdayBookings;
