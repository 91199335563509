/** @jsx jsx */
import React, { useState, useEffect, useContext, useRef } from "react";
import { DatePicker } from "antd";
import { landingCss } from "./easy-coach-landing.styles";
import { useGetEasycoachBookings, useTotals } from "../../utils/customHooks";
import { useNavigate } from "react-router-dom";
import AppContext from "../../utils/context";
import { css, jsx } from "@emotion/react";
import { themeColorPalettes } from "../../utils/utilities";

const { RangePicker } = DatePicker;

const EasyCoachLanding: React.FC<any> = ({ theme, nextRolePage }) => {
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalData, setTotalData] = useState<any>([]);
  const navigate = useNavigate();

  const { assigned_role: assignedRole } = context.profileInfo;

  const { state: easyCoachState } = useGetEasycoachBookings(
    assignedRole,
    nextRolePage
  );
  const { state: landingState } = useTotals(fromDate, toDate);
  // const { state: profileState } = useProfileAxios(
  //   `${process.env.REACT_APP_USER_URL}/users/profile`
  // )
  useEffect(() => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    let todayDate = dd + "/" + mm + "/" + yyyy;
    setFromDate(todayDate);
    return () => {};
  }, []);

  useEffect(() => {
    console.log('profileInfo --> main-content', context.profileInfo)
    if (easyCoachState.status === "fetched") {
      contextRef.current.addBookingsCount(easyCoachState.data.bookings_count);

    } else if (easyCoachState.status === "fetching") {
    } else if (easyCoachState.status === "idle") {
    } else {
    }
    return () => {};
  }, [easyCoachState, nextRolePage]);

  useEffect(() => {
    if (landingState.status === "fetched") {
      // console.log("landing fetched", landingState.data);
      let parsedObj = [];
      if (
        typeof landingState.data[0] === "object" &&
        !Array.isArray(landingState.data[0]) &&
        landingState.data[0] !== null
      ) {
        parsedObj = landingState.data[1];
      } else {
        parsedObj = landingState.data[0][1];
      }

      parsedObj = Object.entries(parsedObj);
      // console.log("parsed", parsedObj);
      setTotalData(parsedObj);
    } else if (landingState.status === "fetching") {
      // console.log("landing fetching", landingState.data);
    } else if (landingState.status === "idle") {
      // console.log("landing idle", landingState.data);
    } else {
      // console.log("landing error", landingState.data);
      navigate("/login");
    }
  }, [landingState, navigate]);

  const handleDateRange = (dateMoment: any, dateStr: any) => {
    let firstDate = dateStr[0];
    let secondDate = dateStr[1];
    firstDate = firstDate.split("-").reverse().join("/");
    secondDate = secondDate.split("-").reverse().join("/");
    console.log("firstDate", firstDate);
    console.log("secondDate", secondDate);
    setFromDate(firstDate);
    setToDate(secondDate);
  };

  const handleComma = (data: any) => {
    return data.toLocaleString();
  };

  return (
    <div
      css={landingCss}
      className={`${
        theme === "Mojave"
          ? "mojave-table"
          : theme === "Sierra"
          ? "sierra-table"
          : "default-table"
      }`}
    >
      <div className="filter-container">
        <div
          className={`${
            theme === "Mojave"
              ? "mojave-filter"
              : theme === "Sierra"
              ? "sierra-filter"
              : "default-filter"
          }`}
        >
          <div
            css={css`
              color: ${themeColorPalettes[theme ? theme : "default"].textColor};
              margin-bottom: 15px;
            `}
            onClick={(e) => e.preventDefault()}
          >
            Date Range
          </div>
          <RangePicker
            onChange={(dateMom, dateStr) => handleDateRange(dateMom, dateStr)}
            style={{ width: 200 }}
          />
        </div>
      </div>
      <span
        css={css`
          color: ${themeColorPalettes[theme ? theme : "default"].textColor};
        `}  
      >From: {fromDate ? fromDate : "---"}</span>
      <span
        css={css`
          color: ${themeColorPalettes[theme ? theme : "default"].textColor};
        `} 
      >To: {toDate ? toDate : "---"}</span>
      <div className="data-row">
        {totalData.map((total: any, idx: number) => {
          let label = total[0].split("_").join(" ").toUpperCase();
          return (
            <div className="total-card" key={idx}>
              <span>{label}</span>
              <span>
                {label === "TOTAL AMOUNT" ? "KES" : ""} {handleComma(total[1])}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EasyCoachLanding;
