/** @jsx jsx */
import { DatePicker, Spin } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { themeColorPalettes } from "../../utils/utilities";
import { useGetAxios, useProfileAxios } from "../../utils/customHooks";
import { useNavigate } from "react-router-dom";
import AppContext from "../../utils/context";

import { analyticsCss } from "./analytics.styles";

import { jsx } from "@emotion/react";

type IAllTimeBookings = {
  theme: string;
};

const Analytics: React.FC<IAllTimeBookings> = ({ theme }) => {
  const context = useContext(AppContext);
  const [allTimeDate, setAllTimeDate] = useState("");
  const [generatedLabels, setGeneratedLabels] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const navigate = useNavigate();

  const { innerWidth, innerHeight } = context.windowSize;

  const { state: profileState } = useProfileAxios(
    `${process.env.REACT_APP_USER_URL}/users/profile`
  );
  const { state } = useGetAxios(
    `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/channel?all_time_analysis=all_time_analysis&date=${allTimeDate}`
  );
  const [allTimeBookings, setAllTimeBookings] = useState<any>("");

  useEffect(() => {
    // if (profileState.status !== 'fetched') return;
    if (state.status === "fetched") {
      let resBody = state.data,
        labels = [],
        labelColors: any = [];
      resBody = resBody.map((stat: any) => {
        return { ...stat, total: 0 };
      });
      setFilteredBookings(resBody);
      // generate a list of labels
      labels = resBody.map((stat: any) => {
        return stat.booking_channel;
      });
      setGeneratedLabels(labels);
      // generate array for label colors
      labels.forEach((label: any) => {
        labelColors.push(themeColorPalettes[theme].headerTextColor);
      });
    }
    return () => {};
  }, [state, profileState, theme]);

  useEffect(() => {
    if (profileState.status === "fetched") {
      console.log("profile fetched", profileState.data);
    } else if (profileState.status === "fetching") {
      console.log("profile fetching", profileState.data);
    } else if (profileState.status === "idle") {
      console.log("profile idle", profileState.data);
    } else {
      console.log("profile error", profileState.data);
      navigate("/login");
    }
  }, [profileState, navigate]);

  const handleDatePick = useCallback((dateMoment, dateString) => {
    console.log(dateString, "date string");
    setAllTimeDate(dateString);
  }, []);

  useEffect(() => {
    // console.log(state, 'state');
    // if (profileState.status !== 'fetched') return;
    if (state.status === "fetched") {
      let resBody = state.data,
        arrayOfStats: any[] = [],
        filteredArrayOfBookings: any[] = [];
      if (resBody.length < filteredBookings.length) {
        filteredArrayOfBookings = [...filteredBookings, ...resBody];
        let seen: any = new Map();
        filteredArrayOfBookings = filteredArrayOfBookings.filter((entry) => {
          let previous;
          // Have we seen this booking_channel before?
          if (seen.hasOwnProperty(entry.booking_channel)) {
            // Yes, grab it and add the total value to it
            previous = seen[entry.booking_channel];
            previous.total = entry.total !== 0 ? entry.total : "bamboozle";
            // Don't keep this entry, we've merged it into the previous one
            return false;
          }
          // Remember that we've seen it
          seen[entry.booking_channel] = entry;
          // Keep this one, we'll merge any others that match into it
          return true;
        });
        // console.log(filteredArrayOfBookings, 'filteredArrayOfBookings');
        filteredArrayOfBookings.forEach((stat: any) => {
          return arrayOfStats.push(stat.total);
        });
      } else {
        // console.log(resBody, 'data fetched');
        resBody.forEach((stat: any) => {
          return arrayOfStats.push(stat.total);
        });
      }
      // console.log(arrayOfStats, 'array of stat');
      setAllTimeBookings(arrayOfStats);
    }
    return () => {};
  }, [state, filteredBookings]);

  return (
    <div css={analyticsCss}>
     
      <DatePicker
        placeholder={"Select Date To filter"}
        style={{
          margin: "0 0 20px 0",
          width: "20vw",
        }}
        onChange={(dateMoment, dateString) =>
          handleDatePick(dateMoment, dateString)
        }
      />

      {!allTimeBookings ? (
        <div className="analytic-loader">
          <Spin size="large" />
        </div>
      ) : (
        <div className="analytics-grid">
          <div className={`doughnut ${theme !== "default" ? "dark" : "light"}`}>
          <Chart
  options={{
    chart: {
      width: 380,
      type: "pie",
      background: 'transparent', // Removal of white background here
      foreColor: "#fff",
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.75,
      },
    },
    stroke: {
      colors: [themeColorPalettes[theme].extraColor],
    },
    dataLabels: {
      style: {
        colors: [themeColorPalettes[theme].backgroundColor],
      },
    },
    labels: generatedLabels ? generatedLabels : [],
    responsive: [
      {
        breakpoint: 500,
        options: {
          chart: {
            width: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      theme: theme !== "default" ? "dark" : "light", // Matching tooltip theme
    },
  }}
  series={allTimeBookings ? allTimeBookings : []}
  type="pie"
  width={innerWidth < 1100 ? 300 : innerWidth < 1500 ? 330 : 380}
  height="300"
/>

          </div>
          <div className={`bar ${theme !== "default" ? "dark" : "light"}`}>
          <Chart
  options={{
    chart: {
      id: "basic-bar",
      background: 'transparent', // Set background to transparent
      offsetX: 0,
      foreColor: "#fff",
      toolbar: {
        tools: {
          pan: true,
        },
      },
      zoom: {
        enabled: true,
      },
    },
    colors: [themeColorPalettes[theme].extraColor],
    grid: {
      show: true,
      borderColor: "#fff", 
      strokeDashArray: 0,
      position: "back",
    },
    dataLabels: {
      enabled: true,
      offsetY: -15,
      style: {
        fontSize: "12px",
        colors: [
          theme !== "default"
            ? themeColorPalettes[theme].iconColor
            : themeColorPalettes[theme].extraColor,
        ],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "50%",
        barHeight: "70%",
        dataLabels: {
          position: "top",
        },
      },
    },
    xaxis: {
      categories: generatedLabels ? generatedLabels : [],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        rotate: -45,
        minHeight: 80,
        trim: true,
        style: {
          fontSize: "11px",
          fontWeight: "500",
          colors: "#fff",
        },
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      theme: theme !== "default" ? "dark" : "light", 
    },
    legend: {
      position: 'top',
    }
  }}
  series={[
    {
      name: "tickets",
      data: allTimeBookings ? allTimeBookings : [],
    },
  ]}
  type="bar"
  width={innerWidth < 1100 ? "700" : innerWidth < 1500 ? "850" : "1000"}
  height={innerHeight > 750 ? "550" : innerHeight > 500 ? "450" : "350"}
/>


          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
