import {
  initialUserDetails,
  initialProfileInfo,
  initialBookingCount,
} from "./initialData";

export const initialState = {
  userDetails: initialUserDetails,
  bookingsCount: {},
  theme: "",
  windowSize: {},
  profileInfo: initialProfileInfo,
  toggleTheme: () => {},
  addUserDetails: () => initialUserDetails,
  addProfileInfo: () => initialProfileInfo,
  addBookingsCount: () => initialBookingCount,
};
