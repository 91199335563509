import React, { useEffect, useCallback } from 'react'
import { Layout, Typography, Button } from 'antd'

import './verification.styles.less'
import { redirect } from 'react-router-dom';
import Axios from 'axios';
import { toast } from 'react-toastify';

const { Content } = Layout;
const { Text, Title } = Typography;

const Verification: React.FC = () => {
  const token = "redire" 

  const verifyToken = useCallback(async () => {
    await Axios.get(
      `${process.env.REACT_APP_USER_URL}/users/verify/${token}`
    ).then((data) => {
      console.log(data);
      toast.success(data.data.data.message);
    }
    ).catch((error) => {console.log(error)});
  }, [token]);

  useEffect(() => {
    if (token) {
      verifyToken()
    }
  }, [token, verifyToken])

  return (
    <div className="verify-container">
      <Layout style={{ minHeight: '100vh' }}>
        <Content style={{ padding: '0 50px' }}>
          <div className="verification">
            <Title level={3}>Verification Success</Title>
            <Text>You have successfully verified your email with dashboard.buupass. click the button below to login</Text>
            <Button 
              type='primary'
              onClick={() => redirect('/')}
            >
              Login
            </Button>
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default Verification
