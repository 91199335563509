import { createContext } from "react";
import { IContextState } from "./interfaces";
import { initialUserDetails, initialProfileInfo } from "./initialData";

const AppContext = createContext<IContextState>({
  userDetails: initialUserDetails,
  bookingsCount: {},
  theme: "",
  windowSize: {},
  profileInfo: initialProfileInfo,
  toggleTheme: (value: string) => {},
  addUserDetails: (value: {}) => {},
  addProfileInfo: (value: {}) => {},
  addBookingsCount: (value: {}) => {},
});

export default AppContext;
