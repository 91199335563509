export const initialUserDetails = {
  assigned_role: "",
  created_at: "",
  department: null,
  email: "",
  first_name: "",
  id: "",
  id_image: null,
  id_number: "",
  is_superuser: false,
  is_suspended: false,
  last_name: "",
  notifications: false,
  phone_number: "",
  region: null,
  role: "",
  status: "",
  super_agent: "",
  token: "",
};

export const initialBookingCount = {
  confirmed: 0,
  confirmed_no_ticket: 0,
  not_confirmed: 0,
};

export const initialProfileInfo = {
  first_name: "john",
  last_name: "doe",
  assigned_role: "user",
  department: "HR",
  email: "pail@accounts.com",
  phone_number: "+25434575476",
};

export const initialPayload = {
  first_name: "hill",
  last_name: "fox",
  email: "fox@octavian.org",
  password: "Gojin76",
  id_number: 31020845,
  phone_number: "+254728639162",
};

export const columnData = [
  {
    title: "Full Name",
    width: 100,
    dataIndex: "name",
    key: "name",
    fixed: "left",
  },
  {
    title: "Operator",
    width: 100,
    dataIndex: "operator",
    key: "operator",
    fixed: "left",
  },
  {
    title: "SMS",
    dataIndex: "sms",
    key: "sms",
    width: 100,
    fixed: undefined,
  },
  {
    title: "BookingId",
    dataIndex: "bookingId",
    key: "bookingId",
    width: 100,
    fixed: undefined,
  },
  {
    title: "TravelId",
    dataIndex: "travelId",
    key: "travelId",
    width: 100,
    fixed: undefined,
  },
  {
    title: "PnR Confirmation",
    dataIndex: "pnr",
    key: "pnr",
    width: 100,
    fixed: undefined,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 100,
    fixed: undefined,
  },
];

export const paidBookingsColumnData = [
  {
    title: "Name",
    width: 50,
    dataIndex: "name",
    key: "name",
    fixed: "left",
    ellipsis: false,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width: 50,
    ellipsis: false,
    fixed: 'left',
  },
  {
    title: "Booking Channel",
    dataIndex: "booking_channel",
    key: "booking_channel",
    width: 50,
    ellipsis: false,
    fixed: 'left',
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
    width: 50,
    ellipsis: false,
    fixed: 'left',
  },
  {
    title: "Booking Id",
    dataIndex: "id",
    key: "id",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Trade Id",
    dataIndex: "trade_id",
    key: "trade_id",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "From",
    dataIndex: "source",
    key: "source",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "To",
    dataIndex: "destination",
    key: "destination",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Departure",
    dataIndex: "departure_date",
    key: "departure_date",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Total",
    dataIndex: "total_amount",
    key: "total_amount",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Sms",
    dataIndex: "sms",
    key: "sms",
    width: 60,
    ellipsis: true,
    fixed: undefined,
  },
];

export const paidBookingsNoTicketColumnData = [
  {
    title: "Name",
    width: 50,
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    fixed: "left",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Booking Id",
    dataIndex: "id",
    key: "id",
    width: 60,
    ellipsis: true,
    fixed: false,
  },
  {
    title: "Booking Channel",
    dataIndex: "booking_channel",
    key: "booking_channel",
    width: 50,
    ellipsis: true,
    fixed: "auto",
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "From",
    dataIndex: "source",
    key: "source",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "To",
    dataIndex: "destination",
    key: "destination",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Departure",
    dataIndex: "departure_date",
    key: "departure_date",
    width: 80,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Total",
    dataIndex: "total_amount",
    key: "total_amount",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Trade Id",
    dataIndex: "trade_id",
    key: "trade_id",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
  {
    title: "Sms",
    dataIndex: "sms",
    key: "sms",
    width: 50,
    ellipsis: true,
    fixed: undefined,
  },
];
