import React, { useState, useEffect, useCallback } from 'react'
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Axios from 'axios';

import './registration.styles.less'
import { toast } from 'react-toastify';

export interface ISignUpProps{
  setSignedIn: (arg: string) => void;
}

const Registration: React.FC<ISignUpProps> = ({ setSignedIn }) => {
  const [payload, setPayload] = useState();
  const [firstNameError, setFirstNameError] = useState([]);
  const [lastNameError, setLastNameError] = useState([])
  const [emailError, setEmailError] = useState([])
  const [idNoError, setIdNoError] = useState([])
  const [phoneNoError, setPhoneNoError] = useState([])
  const [passError, setPassError] = useState([])
  const [success, setSuccess] = useState('')

  const onFinish = (values: any) => {
    setPayload(values);
  };

  const registerApi = useCallback(async (payload: any) => {
    await Axios.post(
      `${process.env.REACT_APP_USER_URL}/users/registration`,
      payload
    )
    .then((data) => {
      console.log(data);
      if (data.status === 201) {
        setSuccess(data.data.data.message)
      }
    })
    .catch((error) => {
      const errorObj = error.response?.data.errors;
      console.log(errorObj, 'registration error');
      if ('password' in errorObj === true) {
        setPassError(errorObj.password);
      }
      if ('first_name' in errorObj === true) {
        setFirstNameError(errorObj.first_name);
      }
      if ('last_name' in errorObj === true) {
        setLastNameError(errorObj.last_name);
      }
      if ('id_number' in errorObj === true) {
        setIdNoError(errorObj.id_number);
      }
      if ('phone_number' in errorObj === true) {
        setPhoneNoError(errorObj.phone_number);
      }
      if ('email' in errorObj === true) {
        setEmailError(errorObj.email);
      }
    })
  }, [])

  useEffect(() => {
    if(payload){
      registerApi(payload);
    }
  }, [payload, registerApi]);

  useEffect(() => {
    if (success) {
      toast.success(success)    
    }
  }, [success])

  useEffect(() => {
    if (passError) {
      passError.map((list) => (
        toast.error(`${list}`)
      ))
    }
  }, [passError])

  useEffect(() => {
    if (firstNameError) {
      firstNameError.map((list) => (
        toast.error(`${list}`)
      ))
    }
  }, [firstNameError])

  useEffect(() => {
    if (lastNameError) {
      lastNameError.map((list) => (
        toast.error(`${list}`)
      ))
    }
  }, [lastNameError])

  useEffect(() => {
    if (emailError) {
      emailError.map((list) => (
        toast.error(`${list}`)
      ))
    }
  }, [emailError])

  useEffect(() => {
    if (phoneNoError) {
      phoneNoError.map((list) => (
        toast.error(`${list}`)
      ))
    }
  }, [phoneNoError])

  useEffect(() => {
    if (idNoError) {
      idNoError.map((list) => (
        toast.error(`${list}`)
      ))
    }
  }, [idNoError])

  return (
    <div className="sign-up">
      <Form
        name="sign-up-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="first_name"
          rules={[
            {
              required: true,
              message: 'Please input your first name!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Jane" />
        </Form.Item>
        <Form.Item
          name="last_name"
          rules={[
            {
              required: true,
              message: 'Please input your last name!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Doe" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} type='email' placeholder="dashboard@buupass.com" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password123"
          />
        </Form.Item>
        <Form.Item
          name="id_number"
          rules={[
            {
              required: true,
              message: 'Please enter your id number!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="31293456" />
        </Form.Item>
        <Form.Item
          name="phone_number"
          rules={[
            {
              required: true,
              message: 'Please input your phone number!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="+254723000222" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button login-button-effect">
            Make Request
          </Button>
        </Form.Item>
        <Form.Item>
          <span className="already-signed-up" onClick={() => setSignedIn('log-in')}>
            already signed up? login here
          </span>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Registration
