/** @jsx jsx */
import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import { Layout, Spin } from "antd";
import { css, jsx } from "@emotion/react";

import SideBar from "../components/sider/sider.component";
import EasyCoachSideBar from "../components/sider/easycoach-side-menu";
import SmartCoachSideBar from "../components/sider/smartcoach-side-menu";
import HeaderComponent from "../components/header/header.component";

import ContentContainer from "../components/main-content/main-content.component";
import AppContext from "../utils/context";
import { contentPages, themeColorPalettes } from "../utils/utilities";
import {
  useProfileAxios,
  useScreenSize
} from "../utils/customHooks";
import { useNavigate } from "react-router-dom";

const landingCss: any = css`
  min-height: 100vh;
  & .loader{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-layout-sider{
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(~"100vh - 64px");
    left: 0;
    & .ant-layout-sider-children{
      & .logo{
        height: 32px;
        margin: 16px;
        display: flex;
        justify-content: center;
        font-size: 1.5em;
      }
    }
  }
  & .ant-layout-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    & .site-layout-background {
      background-color: #fff;
    }
  }
`

const DashboardLanding: React.FC = () => {
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const [contentForm, setContentForm] = useState(0);
  const [collapse, setCollapsed] = useState(false);
  const [theme, setTheme] = useState("Sierra");
  const [userDetails, setUserDetails] = useState<any>();
  const { assigned_role: assignedRole } = context.userDetails;

  const [screenSize] = useScreenSize();
  const navigate = useNavigate();

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    storedTheme ? setTheme(storedTheme) : setTheme("default");
  }, []);

  useLayoutEffect(() => {
    if (context.theme) {
      setTheme(context.theme);
    }
    if (context.profileInfo) {
      setUserDetails(context.profileInfo);
      if (context.profileInfo.assigned_role === "easycoach_user") {
        setContentForm(contentPages["easyCoachLanding"]);
      } else {
        setContentForm(contentPages["paidBookings"]);
      }
    }
  }, [context.theme, context.profileInfo]);

  const { state: profileState } = useProfileAxios(
    `${process.env.REACT_APP_USER_URL}/users/profile`
  );

  // const { response: profileState, error, loading } = useFetchProfile(`${process.env.REACT_APP_USER_URL}/users/profile`, 'get'); 

  useEffect(() => {
    if (profileState.status === "fetched") {
      contextRef.current.addProfileInfo(profileState.data);
      console.log('profile fetched')
    } else if (profileState.status === "fetching") {
      // console.log("profile fetching", profileState.data);
    } else if (profileState.status === "idle") {
      // console.log("profile idle", profileState.data);
    } else {
      // console.log("profile error", profileState.data);
      navigate("/login");
    }
  }, [profileState, navigate]);

  return (
    <Layout
      style={{
        backgroundColor: themeColorPalettes[theme].backgroundColor,
        overflow: "hidden",
      }}
      css={landingCss}
    >
      {profileState.status === "fetched" ? (
        <div>
          <HeaderComponent
            contentForm={contentForm}
            theme={theme}
            setContentForm={setContentForm}
            collapse={collapse}
          />
          <Layout
            className="site-layout"
            style={{
              backgroundColor:
                contentForm === contentPages["analysis"] ? 
                  theme === "Sierra" ? 
                  themeColorPalettes[theme].textColor : 
                    themeColorPalettes[theme].headerTextColor : 
                    themeColorPalettes[theme].backgroundColor,
            }}
          >
            {screenSize.dynamicWidth < 1280 ? (
              <span></span>
            ) : userDetails?.assigned_role === "easycoach_user" ? (
              <EasyCoachSideBar
                setContentForm={setContentForm}
                theme={theme}
                setCollapsed={setCollapsed}
                collapse={collapse}
              />
            ) : userDetails?.assigned_role === "smartcoach_user" ? (
              <SmartCoachSideBar
                setContentForm={setContentForm}
                theme={theme}
                setCollapsed={setCollapsed}
                collapse={collapse}
              />
            ) : (
              <SideBar
                setContentForm={setContentForm}
                theme={theme}
                setCollapsed={setCollapsed}
                collapse={collapse}
              />
            )}
            <ContentContainer
              contentForm={contentForm}
              theme={theme}
              userDetails={profileState.data.userDetails}
            />
          </Layout>
        </div>
      ) : (
        <div className="loader">
          <Spin size="large"></Spin>
        </div>
      )}
    </Layout>
  );
};

export default DashboardLanding;
