import Axios from "axios";
import { useEffect, useReducer, useRef, useState, useContext } from "react";
import AppContext from "./context";

interface IPayload {
  payload?: any;
  type: string;
}
interface IFetchState {
  status: string;
  error: any;
  data?: {
    [key: string]: any;
  };
}

// GET transaction details with {{booking_id}}

// GET API custom hook
export const usePaymentTransactionAPI = (bookingId: string) => {
  const cache = useRef<any>({});
  let initialState: any = {
    status: "idle",
    error: null,
    data: {},
  };

  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    console.log("cached payment details request", bookingId);
    if (!bookingId) return;
    // let url = `https://pay-v2.buupass.com/payments/c2b/payment/${bookingId}`;
    let url = `https://marketplace-v2.buupass.com/marketplace/dashboard/${bookingId}`;
    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[url]) {
        const data = cache.current[url];
        console.log("cached payment details", data);
        dispatch({ type: "FETCHED", payload: data });
      } else {
        await Axios.get(url, {
          headers: {
            Authorization: "Bearer " + context.userDetails.data.token,
            // Authorization: "Api-Key " + process.env.REACT_APP_MARKETPLACE_TOKEN,
          },
        })
          .then((data: any) => {
            console.log("payment details", data.data.data.data);
            cache.current[url] = data.data.data.data; // set response in cache;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data.data.data.data });
          })
          .catch((error) => {
            console.log("payment details error", error);
            if (cancelRequest) return;
            dispatch({ type: "FETCH_ERROR", payload: error.message });
          });
        // const data = await response.json();
      }
    };

    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [bookingId]);

  return { state };
};

// GET API custom hook
export const useGetAxios = (url: string) => {
  const cache = useRef<any>({});
  let initialState: any = {
    status: "idle",
    error: null,
    data: {},
  };

  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    console.log('url dynamic date', url)
    if (!url) return;
    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: "FETCHED", payload: data });
      } else {
        await Axios.get(url, {
          headers: {
            Authorization: "Bearer " + context.userDetails.data.token,
          },
        })
          .then((data: any) => {
            console.log("fetched data", data.data.data.response);
            cache.current[url] = data.data.data.response; // set response in cache;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data.data.data.response });
          })
          .catch((error) => {
            if (cancelRequest) return;
            dispatch({ type: "FETCH_ERROR", payload: error.message });
          });
        // const data = await response.json();
      }
    };

    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return { state };
};

export const useGetEasycoachBookings = (assignedRole: string, nextRolePage: number) => {
  const cache = useRef<any>({});
  let paidBookingState: any = {
    status: "idle",
    error: null,
    data: {},
    pageNo: 1,
  };

  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...paidBookingState, status: "fetching" };
      case "IDLE":
        return { ...paidBookingState, status: "idle" };
      case "FETCHED":
        return {
          ...paidBookingState,
          status: "fetched",
          data: action.payload,
          pageNo: nextRolePage,
        };
      case "FETCH_ERROR":
        return { ...paidBookingState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, paidBookingState);

  useEffect(() => {
    let cancelRequest = false;
    let requestCount = 1
    if (assignedRole !== 'easycoach_user') return;
    let newUrl =  `${
      process.env.REACT_APP_MARKETPLACE_URL
    }dashboard/ticket/unsent?is_customer_care=is_customer_care&is_operator=${assignedRole}&paid_with_tickets=paid_with_tickets&page=${nextRolePage}`;
    const fetchData = async () => {
      requestCount = requestCount + 1
      dispatch({ type: "FETCHING" });
      if (cache.current[newUrl]) {
        const data = cache.current[newUrl];
        dispatch({ type: "FETCHED", payload: data });
        console.log("paid bookings", data);
      } else {
        await Axios.get(newUrl, {
          headers: {
            Authorization: "Bearer " + context.userDetails.data.token,
          },
        })
          .then((data: any) => {
            cache.current[newUrl] = data.data.data; // set response in cache;
            cache.current[nextRolePage] = nextRolePage; // set pagination number in cache;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data.data.data });
          })
          .catch((error) => {
            if (cancelRequest) return;
            dispatch({
              type: "FETCH_ERROR",
              payload: error.response?.data?.detail,
            });
          });
        // const data = await response.json();
      }
    };
    console.log("useEffect triggered with url:", assignedRole);
    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [assignedRole, nextRolePage]);

  return { state };
};


// GET endpoint with data.data and not data.response
export const useGetPaidBookings = (url: string, nextRolePage: number) => {
  const cache = useRef<any>({});
  let paidBookingState: any = {
    status: "idle",
    error: null,
    data: {},
    pageNo: 1,
  };

  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...paidBookingState, status: "fetching" };
      case "IDLE":
        return { ...paidBookingState, status: "idle" };
      case "FETCHED":
        return {
          ...paidBookingState,
          status: "fetched",
          data: action.payload,
          pageNo: nextRolePage,
        };
      case "FETCH_ERROR":
        return { ...paidBookingState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, paidBookingState);

  useEffect(() => {
    let cancelRequest = false;
    let requestCount = 1
    if (!url) return;
    let newUrl = url + `${nextRolePage}`;
    const fetchData = async () => {
      requestCount = requestCount + 1
      dispatch({ type: "FETCHING" });
      if (cache.current[newUrl]) {
        const data = cache.current[newUrl];
        dispatch({ type: "FETCHED", payload: data });
        console.log("paid bookings", data);
      } else {
        await Axios.get(newUrl, {
          headers: {
            Authorization: "Bearer " + context.userDetails.data.token,
          },
        })
          .then((data: any) => {
            cache.current[newUrl] = data.data.data; // set response in cache;
            cache.current[nextRolePage] = nextRolePage; // set pagination number in cache;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data.data.data });
          })
          .catch((error) => {
            if (cancelRequest) return;
            dispatch({
              type: "FETCH_ERROR",
              payload: error.response?.data?.detail,
            });
          });
        // const data = await response.json();
      }
    };
    console.log("useEffect triggered with url:", url);
    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [url, nextRolePage]);

  return { state };
};

export const useTotals = (fromDate: string, toDate: string) => {
  const cache = useRef<any>({});
  let landingState: any = {
    status: "idle",
    error: null,
    data: {},
    pageNo: 1,
  };

  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...landingState, status: "fetching" };
      case "IDLE":
        return { ...landingState, status: "idle" };
      case "FETCHED":
        return {
          ...landingState,
          status: "fetched",
          data: action.payload,
        };
      case "FETCH_ERROR":
        return { ...landingState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, landingState);

  useEffect(() => {
    let cancelRequest = false;
    if (!fromDate) return;
    let newUrl = `${process.env.REACT_APP_MARKETPLACE_URL}booking/total-tickets?from=${fromDate}&to=${toDate}&is_operator=easycoach`
    // console.log("newUrl", newUrl);
    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[newUrl]) {
        const data = cache.current[newUrl];
        dispatch({ type: "FETCHED", payload: data });
        console.log("totals easy coach cache", data);
      } else {
        await Axios.get(newUrl, {
          headers: {
            Authorization: "Bearer " + context.userDetails.data.token,
          },
        })
          .then((data: any) => {
            console.log("totals easy coach", data);
            cache.current[newUrl] = data.data.tickets; // set response in cache;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data.data.tickets });
          })
          .catch((error) => {
            if (cancelRequest) return;
            dispatch({
              type: "FETCH_ERROR",
              payload: error.response?.data?.detail,
            });
          });
        // const data = await response.json();
      }
    };
    // console.log("useEffect triggered with fromDate:", fromDate);
    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [fromDate, toDate]);

  return { state };
};

export const useTripsAccount = () => {
  const cache = useRef<any>({});
  let landingState: any = {
    status: "idle",
    error: null,
    data: {},
    pageNo: 1,
  };

  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...landingState, status: "fetching" };
      case "IDLE":
        return { ...landingState, status: "idle" };
      case "FETCHED":
        return {
          ...landingState,
          status: "fetched",
          data: action.payload,
        };
      case "FETCH_ERROR":
        return { ...landingState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, landingState);

  useEffect(() => {
    let cancelRequest = false;
    let newUrl = `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/account-data`
    //let newUrl = 'https://dev.marketplaceapi.buupass.com/marketplace/dashboard/account-data'

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[newUrl]) {
        const data = cache.current[newUrl];
        dispatch({ type: "FETCHED", payload: data });
        console.log("totals trips cache", data);
      } else {
        await Axios.get(newUrl, {
          headers: {
            Authorization: "Bearer " + context.userDetails.data.token,
          },
        })
          .then((data: any) => {
            console.log("totals trips", data);
            cache.current[newUrl] = data.data; // set response in cache;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data.data });
          })
          .catch((error) => {
            if (cancelRequest) return;
            dispatch({
              type: "FETCH_ERROR",
              payload: error.response?.data?.detail,
            });
          });
        // const data = await response.json();
      }
    };
    // console.log("useEffect triggered with fromDate:", fromDate);
    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, []);

  return { state };
};

// GET endpoint with data and not data.response / data.data
export const useGetFilters = (
  url: string,
  searchQuery: string,
  nextPageNumber: any
) => {
  const cache = useRef<any>({});
  let paidBookingState: any = {
    status: "idle",
    error: null,
    data: {},
    resetUrl: "",
  };
  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...paidBookingState, status: "fetching" };
      case "IDLE":
        return { ...paidBookingState, status: "idle" };
      case "FETCHED":
        return { ...paidBookingState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...paidBookingState, status: "error", error: action.payload };
      case "RESET_URL":
        return {
          ...paidBookingState,
          status: "fetched",
          resetUrl: action.payload,
        };
      default:
        return state;
    }
  }, paidBookingState);

  useEffect(() => {
    let cancelRequest = false,
      resetURL = url;
    if (!url) return;
    if (!searchQuery) return;
    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (nextPageNumber !== cache.current.currentPage) {
        if (url.includes("dashboard/date/range?")) {
          let dateRangeConstruct = searchQuery.includes(' ') ? searchQuery.split(" ") : searchQuery;
          resetURL = `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/date/range?`+
            `start_departure_date=${searchQuery.includes(' ')? dateRangeConstruct[0] : dateRangeConstruct}&`+
            `end_departure_date=${searchQuery.includes(' ')? dateRangeConstruct[1] : dateRangeConstruct}&is_customer_care=is_customer_care&page=${nextPageNumber}`;
        } else {
          resetURL = `${
            process.env.REACT_APP_MARKETPLACE_URL
          }dashboard/bookings/?search=${searchQuery}&page=${nextPageNumber}&is_operator=${
            context.profileInfo.assigned_role === "easycoach_user"
              ? "easycoach"
              : "admin"
          }`;
        }
        // cache.current.resetURL = resetURL;
      }
      if (cache.current[url] && nextPageNumber === cache.current.currentPage) {
        dispatch({ type: "IDLE" });
        // console.log('cached filter data', cachedData)
        // console.log('next page number', nextPageNumber)
      } else {
        console.log('context.userDetails filter', context.userDetails)
        await Axios.get(resetURL, {
          headers: {
            Authorization: "Bearer " + context.userDetails.data.token,
            // Authorization: "Api-Key " + process.env.REACT_APP_MARKETPLACE_TOKEN,
          },
        })
          .then((data: any) => {
            let resBody = {};
            if (resetURL.includes("dashboard/date/range?")) {
              resBody = data.data.data;
            } else {
              resBody = data.data;
            }

            cache.current[resetURL] = resBody; // set response in cache;
            cache.current.currentPage = nextPageNumber;
            if (cancelRequest) return;
            // dispatch({ type: 'RESET_URL', payload: resetURL });
            dispatch({
              type: "FETCHED",
              payload: { data: resBody, url: resetURL },
            });
          })
          .catch((error) => {
            if (cancelRequest) return;
            dispatch({
              type: "FETCH_ERROR",
              payload: error.response.data.detail,
            });
          });
        // const data = await response.json();
      }
    };

    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [url, searchQuery, nextPageNumber]);

  return { state };
};

// GET QR code 

export const useFetchQr = (url: string) => {
  const cache = useRef<any>({});
  const prevUrl = useRef('');

  let initialState: any = {
    status: "idle",
    error: null,
    data: {},
  };

  const context = useContext(AppContext);
  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) return;

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[url]) {
        const data = cache.current[url];
        console.log("fetched QR cache", data);
        dispatch({ type: "FETCHED", payload: data });
      } else {
          await Axios.get(url, {
            headers: { 
              Authorization: "Bearer " + context.userDetails.data.token 
            },
          })
            .then((data: any) => {
              console.log("fetched qr data", data.data.data);
              cache.current[url] = data.data.data; // set response in cache;
              if (cancelRequest) return;
              dispatch({ type: "FETCHED", payload: data.data.data });
            })
            .catch((error) => {
              if (cancelRequest) return;
              console.log("fetched qr error", error.response);
              dispatch({ type: "FETCH_ERROR", payload: error.message });
            });
        // const data = await response.json();
      }
    };

    fetchData();
    
    return function cleanup() {
      cancelRequest = true;
    };
  }, [url,]);

  return { state };
};
// GET profile info with login token
export const useProfileAxios = (url: string) => {
  const cache = useRef<any>({});
  const prevUrl = useRef('');

  const context = useContext(AppContext);
  let initialState: any = {
    status: "idle",
    error: null,
    data: {},
  };

  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) return;

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[url]) {
        const data = cache.current[url];
        console.log("fetched profile cache", data);
        dispatch({ type: "FETCHED", payload: data });
      } else {
        if (context.userDetails?.data?.token) { 
          await Axios.get(url, {
            headers: { 
              Authorization: "Bearer " + context.userDetails.data.token 
            },
          })
            .then((data: any) => {
              localStorage.setItem("role", data.data.data.assigned_role);
              cache.current[url] = data.data.data; // set response in cache;
              if (cancelRequest) return;
              dispatch({ type: "FETCHED", payload: data.data.data });
            })
            .catch((error) => {
              if (cancelRequest) return;
              console.log("fetched profile error", error.response);
              dispatch({ type: "FETCH_ERROR", payload: error.message });
            });
        } else {
          dispatch({ type: "FETCH_ERROR", payload: 'No token present' });
        }
        // const data = await response.json();
      }
    };
    console.log("profile url", url);
    fetchData();
    
    return function cleanup() {
      cancelRequest = true;
    };
  }, [url,]);

  return { state };
};

// POST login to user-v2 API
export const usePostAxios = (url: string, payload: any) => {
  const cache = useRef<any>({});
  let initialState: any = {
    status: "idle",
    error: null,
    data: {},
  };

  const [state, dispatch] = useReducer((state: {}, action: IPayload) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) return;
    if (!payload) return;

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: "FETCHED", payload: data });
      } else {
        await Axios.post(url, payload)
          .then((data: any) => {
            console.log("logged in data", data.data.data);
            cache.current[url] = data.data.data; // set response in cache;
            if (cancelRequest) return;
            dispatch({ type: "FETCHED", payload: data.data.data });
          })
          .catch((error) => {
            if (cancelRequest) return;

            console.log("error log in", error);
            dispatch({ type: "FETCH_ERROR", payload: error.message });
          });
        // const data = await response.json();
      }
    };
    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [url, payload]);

  return { state };
};

export function useScreenSize() {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return [screenSize];
}
