import { IContextState, actions } from "./interfaces";
import {
  ADD_USER_DETAILS,
  TOGGLE_THEME,
  ADD_PROFILE_INFO,
  ADD_WINDOW_SIZE,
  ADD_BOOKINGS_COUNT,
} from "./types";

export default (state: IContextState, action: actions) => {
  switch (action.type) {
    case ADD_USER_DETAILS:
      return { ...state, userDetails: action.userDetails };
    case ADD_BOOKINGS_COUNT:
      return { ...state, bookingsCount: action.bookingsCount };
    case TOGGLE_THEME:
      return { ...state, theme: action.theme };
    case ADD_PROFILE_INFO:
      return { ...state, profileInfo: action.profileInfo };
    case ADD_WINDOW_SIZE:
      return { ...state, windowSize: action.windowSize };
    default:
      return state;
  }
};
