import React from 'react'
import { themeColorPalettes } from '../../utils/utilities';

interface IDescriptionProps{
  title?: string;
  content?: string;
  theme?: string;
}

const DescriptionItem: React.FC<IDescriptionProps> = ({ title, content, theme }) => {
  return (
    <div
      className="site-description-item-profile-wrapper"
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
        color: theme === 'Sierra'? '': themeColorPalettes[theme? theme: 'default'].headerTextColor,
      }}
    >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
  )
}

export default DescriptionItem;
