import { css, jsx } from "@emotion/react";

export const loginCss = css`
  padding: 3vw;
  & .ant-form{
    background-color: #ffffff;
    padding: 20px;
    max-width: 300px;
    border: 0.01em solid rgb(196, 203, 206);
    font-weight: bolder;
    & .ant-row{
      & .ant-col{
        & .ant-form-item-control-input{
          & .ant-form-item-control-input-content{
            & .login-form-reset{
              float: left;
              font-size: 0.9em;
              cursor: pointer;
              color: #eb712f;
            }
            & .login-form-forgot{
              float: right;
              font-size: 0.9em;
              padding-top: 0.3vh;
              cursor: pointer;
              color: #eb712f;
            }
            & .login-form-button{
              width: 100%;
              font-size: 1.1em;
              text-transform: uppercase;
              color: #001a33;
              font-weight: bolder;
              &.ant-btn-primary{
                background-color: white;
                border-color: none;
              }
            }
            & .login-button-effect{
              transition: all 0.3s;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all 0.3s;
                border-top-width: 1px;
                border-bottom-width: 1px;
                border-top-style: solid;
                border-bottom-style: solid;
                border-top-color: rgba(33, 0, 87, 0.5);
                border-bottom-color: rgba(139, 0, 0, 0.5);
                transform: scale(0.1, 1);
              }
              :hover span {
                letter-spacing: 2px;
                color: black;
              }
              :hover::before {
                opacity: 1;	
                transform: scale(1, 1);	
              }
              ::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s;
                background-color: rgba(228, 136, 15, 0.3);
              }
              :hover::after {
                opacity: 0;	
                transform: scale(0.1, 1);
              }
            }
            & .ant-input-affix-wrapper{
              & .ant-input{
                font-size: 1em;
                font-weight: bolder;
              }
            }
            & .ant-checkbox-wrapper{
              font-size: 0.9em;
            }
          }
        }
        & .ant-form-item-explain{
          font-size: 0.9em;
        }
      }
    }
  }
`