import React, { useState, useCallback, useEffect } from 'react'

import './all-roles.styles.less'
import Axios from 'axios'
import { List, Button, Typography } from 'antd'
import { themeColorPalettes } from '../../utils/utilities'
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md'
import { toast } from 'react-toastify'
import { redirect } from 'react-router-dom'

const { Title, Text } = Typography;

interface IAllRoles{
  theme: string;
  refreshList: boolean;
  setRefreshList: (args: boolean) => void;
}

const AllRoles: React.FC<IAllRoles> = ({ 
  theme,
  refreshList,
  setRefreshList
}) => {
  const [roles, setRoles] = useState([])
  const [token, setToken] = useState('')
  
  const [nextRolePage, setNextRolePage] = useState(1);
  const [itemToDelete, setItemToDelete] = useState('');

  const fetchRoles = useCallback(async () => {
    if (token) {
      await Axios.get(
        `${process.env.REACT_APP_USER_URL}/role/retrieve?page=${nextRolePage}`,
        {
          headers: { Authorization: "Bearer " + token }
        }
      ).then((data) => {
        console.log(data, 'data fetched');
        setRoles(data.data.rows)
        
      }).catch(error => {
        console.log(error.response.data, 'error fetching Roles');
        redirect('/login')
        toast.error(error.response.data.detail,  {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: 1000,
        });
      })
    }
  }, [token, nextRolePage,])

  useEffect(() => {
    if(refreshList){
      fetchRoles()
    }
    return () => {
      setRefreshList(false)
    }
  }, [refreshList, setRefreshList, fetchRoles])

  const handleDelete = useCallback(async (roleId: string) => {
    if (token) {
      await Axios.patch(
        `${process.env.REACT_APP_USER_URL}/role/delete/${roleId}`, '',
        {
          headers: { Authorization: "Bearer " + token }
        }
      ).then((data) => {
        console.log(data, 'role deleted');
        toast.success(data.data.data.message)
        fetchRoles();  
      }).catch(error => {
        console.log(error.response, 'error deleting role');
      })
    }
  }, [token, fetchRoles])

  useEffect(() => {
    if (itemToDelete) {
      handleDelete(itemToDelete)
    }
    return () => {
      setItemToDelete('')
    }
  }, [itemToDelete, handleDelete])

  useEffect(() => {
    if(nextRolePage >= 1){
      fetchRoles()
    }
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken)
    }
    return () => {
      setRoles([])
    }
  }, [nextRolePage, fetchRoles])
  

  return (
    <div className="all-roles">
      {roles? <List
        size="large"
        header={
          <Title 
            level={4}
            style={{
              color: themeColorPalettes[theme? theme: 'default'].extraColor,
            }}
          >
            All roles
          </Title>
        }
        dataSource={roles}
        renderItem={(item: any) => (
          <List.Item key={item.id} style={{
            backgroundColor: themeColorPalettes[theme? theme: 'default'].textColor,
          }}>
            <List.Item.Meta
              title={`${item.name}`}
              style={{ color: themeColorPalettes[theme? theme: 'default'].headerTextColor }}
            /> 
            <Button 
              type='primary' 
              className='delete-button-effect'
              onClick={() => setItemToDelete(item.id)}
              style={{ 
                backgroundColor: themeColorPalettes[theme].extraColor
              }}
            >
              Delete
            </Button>
          </List.Item>
        )}
      /> : ''}
      <Button
        className="next-arrow"
        type="primary" 
        shape="circle"
        icon={<MdNavigateNext />}
        onClick={() => setNextRolePage(nextRolePage + 1)}
        style={{ 
          backgroundColor: themeColorPalettes[theme].extraColor,
          border: 'none'
        }} 
      />
      <Button
        className="back-arrow"
        type="primary" 
        shape="circle"
        icon={<MdNavigateBefore />}
        onClick={() => setNextRolePage(nextRolePage - 1)}
        style={{ 
          backgroundColor: themeColorPalettes[theme].extraColor,
          border: 'none'
        }} 
      />
      <Text
        className='pagination-count'
        strong={true}
        style={{ 
          color: themeColorPalettes[theme].extraColor,
        }} 
      >
        Page ~ {nextRolePage}
      </Text>
    </div>
  )
}

export default AllRoles
