import React, { useState, useCallback, useEffect } from 'react'

import './profile.styles.less'
import { Row, Col, Layout, Typography } from 'antd'
import DescriptionItem from './description-item.component'
import { themeColorPalettes } from '../../utils/utilities';
import { IProps } from '../add-users/add-users.component';
import Axios from 'axios';
import UpdateProfile from './update-profile.component';

const { Content } = Layout;
const { Title } = Typography;

const Profile: React.FC<IProps> = ({ theme }) => {
  const [profile, setProfile] = useState<any>({})
  const [token, setToken] = useState('')

  const fetchProfile = useCallback(async () => {
    if (token) {
      await Axios.get(
        `${process.env.REACT_APP_USER_URL}/users/profile`,
        {
          headers: { Authorization: "Bearer " + token }
        }
      ).then((data) => {
        console.log(data, 'data fetched');
        setProfile(data.data.data)
        
      }).catch(error => {
        console.log(error.response, 'error fetching profiles');
      })
    }
  }, [token])

  useEffect(() => {
    fetchProfile()
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken)
    }
    return () => {
      setProfile({})
    }
  }, [fetchProfile])

  return (
    <Content>
      <div 
        className="site-layout-background"
        style={{ 
          padding: 24,
          minHeight: 'auto',
          backgroundColor: themeColorPalettes[theme? theme: 'default'].textColor,
        }}
      >
      <Title level={4}>
        Personal Info
      </Title>
      <Row>
        <Col span={12}>
          <DescriptionItem title="First Name" content={profile.first_name} theme={theme}/>
        </Col>
        <Col span={12}>
          <DescriptionItem title="Last Name" content={profile.last_name} theme={theme}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Email" content={profile.email} theme={theme}/>
        </Col>
        <Col span={12}>
          <DescriptionItem title="Phone Number" content={profile.phone_number} theme={theme} />
        </Col>
      </Row>
      {/* <Row>
        <Col span={12}>
          <DescriptionItem title="Birthday" content="February 2,1900" />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Website" content="-" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Message"
            content="Make things as simple as possible but no simpler."
          />
        </Col>
      </Row> */}
      </div>
      <UpdateProfile theme={theme} profile={profile} />
    </Content>
  )
}

export default Profile
