/** @jsx jsx */
import React, { useContext, useState, useEffect } from "react";
import { Layout, Typography } from "antd";
import { FiLogOut } from "react-icons/fi";  
import { FiMoon, FiSun } from "react-icons/fi"; 
import { useNavigate } from "react-router-dom";
import { jsx, css } from "@emotion/react";
import AppContext from "../../utils/context";
import { useScreenSize } from "../../utils/customHooks";
import { contentPages, themeColorPalettes } from "../../utils/utilities";
import { headerCss, sierraCss } from "./header.styles";

const EasycoachLogo = require("../../imgs/easycoach_logo.jpeg");
const TripsLogo = require("../../imgs/trips_logo.svg");
const SmartcoachLogo = require("../../imgs/smartcoach_logo.webp");

const { Header } = Layout;
const { Text } = Typography;

interface IHeaderProps {
  setContentForm: (arg: number) => void;
  theme: string;
  contentForm: number;
  collapse: boolean;
}

const HeaderComponent: React.FC<IHeaderProps> = ({
  contentForm,
  theme,
  setContentForm,
  collapse,
}) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [screenSize] = useScreenSize();
  const [themeValue, setThemeValue] = useState<string>('default');

  // Handle storing and toggling theme
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    setThemeValue(storedTheme ? storedTheme : "default");
  }, []);

  const handleThemeToggle = () => {
    const newTheme = themeValue === "default" ? "Sierra" : "default";
    setThemeValue(newTheme);
    context.toggleTheme(newTheme); // Assuming context handles the theme switching
    localStorage.setItem("theme", newTheme);
  };

  const handleMenuSelect = (key: string) => {
    if (key === '11') {
      setContentForm(contentPages["paidBookings"]);
    } else if (key === '10') {
      setContentForm(contentPages["paidNoTickets"]);
    } else if (key === '3') {
      setContentForm(contentPages["unconfirmedBookings"]);
    } else if (key === '4') {
      setContentForm(contentPages["allBookings"]);
    } else if (key === '2') {
      setContentForm(contentPages["todayBookings"]);
    } else if (key === '5') {
      setContentForm(contentPages["yesterDayBookings"]);
    } else if (key === '7') {
      setContentForm(contentPages["analysis"]);
    } else if (key === '14') {
      setContentForm(contentPages["tripsLanding"]);
    } else if (key === '6') {
      navigate("/login");
    }
  };

  return (
    <Header
      css={theme === "Sierra" ? sierraCss : headerCss}
      style={{
        padding: 0,
        backgroundColor: themeColorPalettes[theme ? theme : "default"].sideBarColor,
        color: themeColorPalettes[theme ? theme : "default"].iconColor,
      }}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%; /* Ensures the whole header is utilized */
          padding-right: 20px; /* Add some padding to ensure space on the right */
        `}
      >
        {/* Logo Handling */}
        {collapse || screenSize.dynamicWidth < 570 ? (
          context.profileInfo.assigned_role === "easycoach_user" ? (
            <img src={EasycoachLogo} alt="logo" className="logo-img-sm" />
          ) : context.profileInfo.assigned_role === "smartcoach_user" ? (
            <img src={SmartcoachLogo} alt="logo" className="square-logo-img-sm" />
          ) : context.profileInfo.assigned_role === "trips" ? (
            <img src={TripsLogo} alt="logo" className="square-logo-img-sm" />
          ) : (
            <div className="logo" onClick={() => navigate("/")}>
              <Text style={{ color: themeColorPalettes[theme].iconColor, fontSize: screenSize.dynamicWidth > 730 ? "25px" : "15px" }}>
                B
              </Text>
              <Text style={{ color: themeColorPalettes[theme].iconColor, fontSize: screenSize.dynamicWidth > 730 ? "25px" : "15px" }}>
                P
              </Text>
            </div>
          )
        ) : (
          <div onClick={() => navigate("/")} className="logo">
            <Text style={{ color: themeColorPalettes[theme].iconColor }}>Buu</Text>
            <Text style={{ color: themeColorPalettes[theme].iconColor }}>Pass</Text>
          </div>
        )}

        {/* Moved Logout and Theme Toggle to the right side */}
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: auto; /* Ensures they stay aligned to the right */
          `}
        >
          {/* Theme Toggle */}
          <div
            onClick={handleThemeToggle}
            css={css`
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-right: 20px; /* Add space between theme switcher and logout */
            `}
          >
            {themeValue === "default" ? (
              <FiMoon size={24} color="white" />
            ) : (
              <FiSun size={24} color="white" />
            )}
          </div>

          {/* Logout Button */}
          <div
            onClick={() => navigate("/login")}
            css={css`
              display: flex;
              align-items: center;
              cursor: pointer;
            `}
          >
            <FiLogOut />
            <span style={{ marginLeft: "10px" }}>Log Out</span>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
