import React, { useCallback, useState, useEffect } from 'react'
import { IProps } from '../add-users/add-users.component';

import './roles.styles.less'
import { Form, Input, Button, Layout } from 'antd';
import { themeColorPalettes } from '../../utils/utilities';
import Axios from 'axios';
import { toast } from 'react-toastify';
import AllRoles from './all-roles.component';

const roleLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Content } = Layout;

const Roles: React.FC<IProps> = ({ theme }) => {

  const [payload, setPayload] = useState();
  const [roleError, setRoleError] = useState([]);
  const [token, setToken] = useState('')
  const [refreshList, setRefreshList] = useState(false);
  
  const onFinish = (values: any) => {
    console.log(values);
    setPayload(values)
  };

  const addRole = useCallback(async (payload: any) => {
    await Axios.post(
      `${process.env.REACT_APP_USER_URL}/role/register`,
      payload,
      {
        headers: { Authorization: "Bearer " + token }
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.status === 201) {
        toast.success(data.data.data.message);
      }
    })
    .catch((error) => {
      const errorObj = error.response?.data.errors;
      console.log(errorObj);
      
      if ('name' in errorObj === true) {
        setRoleError(errorObj.name);
      }

    })
  }, [token]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken)
    }
  }, [setToken])

  useEffect(() => {
    if(payload){
      addRole(payload)
      setTimeout(() => {
        setRefreshList(true);
      }, 2000);
    }
  }, [
    payload,
    addRole
  ]);

  useEffect(() => {
    if (roleError) {
      roleError.map((list) => (
        toast.error(`${list}`)
      ))
    }
  }, [roleError])

  return (
    <Content>
      <div
        className="roles-section"
        style={{ 
          padding: 24,
          minHeight: 'auto',
          backgroundColor: themeColorPalettes[theme? theme: 'default'].textColor,
        }}
      >
        <Form 
          {...roleLayout} 
          name="nest-messages" 
          onFinish={onFinish} 
          className={`${theme === 'default'? 'label-styling': ((theme === 'Mojave')? 'mojave-styling':'')}`}
        >
          <Form.Item 
            name='name'
            label="Role name" 
            rules={[{ message: 'Please enter in a role!', }]}
            colon={false}
          >
            <Input placeholder='admin'/>
          </Form.Item>
          <Form.Item wrapperCol={{ ...roleLayout.wrapperCol, offset: 12 }}>
            <Button 
              htmlType="submit" style={{ 
              backgroundColor: themeColorPalettes[theme].extraColor
            }}>
              Add Role
            </Button>
          </Form.Item>
        </Form>
      </div>
      <AllRoles 
        theme={theme}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
      />
    </Content>
  )
}

export default Roles;
