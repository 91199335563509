/** @jsx jsx */
import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import {sideBarCss} from "./easycoach-sider.styles";
import { AiFillHome } from "react-icons/ai";
import { GiOpenBook } from "react-icons/gi";
import { contentPages, themeColorPalettes } from "../../utils/utilities";
import { BsFillFolderFill } from "react-icons/bs";
import { jsx } from "@emotion/react";

const { Sider } = Layout;

interface ISideBarProps {
  setContentForm: (arg: number) => void;
  setCollapsed: (arg: boolean) => void;
  theme: string;
  collapse: boolean;
}

const SmartCoachSideBar: React.FC<ISideBarProps> = ({
  setContentForm,
  setCollapsed,
  theme,
  collapse,
}) => {
  const [selected, setSelected] = useState("6");
  const onCollapse = (collapse: boolean) => {
    setCollapsed(collapse);
  };

  const toggle = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setCollapsed(!collapse);
  };
  return (
    <div css={sideBarCss}>
      <Sider
        collapsible
        collapsed={collapse}
        onCollapse={onCollapse}
        trigger={null}
        style={{
          backgroundColor: themeColorPalettes[theme].sideBarColor,
          height: "100vh",
        }}
      >
        <div
          className={`
            style-menu
            ${theme === "Mojave" ? "mojave-theme" : ""}
          `}
        >
          <Menu
            theme="dark"
            defaultSelectedKeys={["sub4"]}
            defaultOpenKeys={[
              `${selected === "4" || selected === "5" ? "sub4" : ""}`,
            ]}
            mode="inline"
            style={{
              backgroundColor: themeColorPalettes[theme].sideBarColor,
              color: themeColorPalettes[theme].iconColor,
              // fontSize: screenSize.dynamicWidth < 1353 ? "8px" : "18px",
            }}
            onSelect={({ key }) => {
              setSelected(key);
            }}
          >
            <Menu.Item
              key="11"
              onClick={() => setContentForm(contentPages["paidBookings"])}
              style={{
                backgroundColor: `${
                  selected === "11" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "11"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <AiFillHome />
              <span>Paid</span>
            </Menu.Item>
            <Menu.Item
              key="10"
              onClick={() => setContentForm(contentPages["paidNoTickets"])}
              style={{
                backgroundColor: `${
                  selected === "10" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "10"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <GiOpenBook />
              <span>Paid No tickets</span>
            </Menu.Item>
            {/* <Menu.Item
              key="12"
              onClick={() => setContentForm(contentPages["paidNoPnr"])}
              style={{
                backgroundColor: `${
                  selected === "12" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "12"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <MdEventBusy />
              <span>Paid No PNR</span>
            </Menu.Item>*/}
            <Menu.Item
              key="3"
              onClick={() => setContentForm(contentPages["unconfirmedBookings"])}
              style={{
                backgroundColor: `${
                  selected === "3" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "3"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <BsFillFolderFill />
              <span
                style={{
                  lineBreak: "auto",
                }}
              >
                Unconfirmed Bookings
              </span>
            </Menu.Item>
            {/* <Menu.Item
              key="4"
              onClick={() => setContentForm(contentPages["allBookings"])}
              style={{
                backgroundColor: `${
                  selected === "4" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "4"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <BiArchive />
              <span
                style={{
                  lineBreak: "auto",
                }}
              >
                All Bookings
              </span>
            </Menu.Item>*/}
            {/* <Menu.Item
              key="2"
              onClick={() => setContentForm(contentPages["todayBookings"])}
              style={{
                backgroundColor: `${
                  selected === "2" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "2"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <FiSearch />
              <span>Today</span>
            </Menu.Item>*/}
            {/*<Menu.Item
              key="5"
              onClick={() => setContentForm(contentPages["yesterDayBookings"])}
              style={{
                backgroundColor: `${
                  selected === "5" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "5"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <FaUsers />
              <span>Yesterday</span>
            </Menu.Item> */}

            {/* <Menu.Item
              key="7"
              onClick={() => setContentForm(contentPages["analysis"])}
              style={{
                backgroundColor: `${
                  selected === "7" ? themeColorPalettes[theme].iconColor : ""
                }`,
                color: `${
                  selected === "7"
                    ? theme !== "default"
                      ? themeColorPalettes[theme].backgroundColor
                      : themeColorPalettes[theme].headerTextColor
                    : ""
                }`,
              }}
            >
              <FaSuitcase />
              <span>All Time Analysis</span>
            </Menu.Item> */}
          </Menu>
        </div>
        {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: `trigger ${!collapse ? "" : "trigger-marginLeft-shrink"}`,
          onClick: toggle,
        })}
      </Sider>
    </div>
  );
};

export default SmartCoachSideBar;
