import { css } from "@emotion/react";

export const filterCss = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 97%;
  margin: 0 0 20px 0;
  & .delete-icon{
    color: aliceblue;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    width: 8vw;
    padding: 10px;
    background-color: #950740;
  }
  & .default-filter{
    width: 50%;
    display: flex;
    flex-direction: row;
    & .ant-input-search{
      &::before{
        outline: none;
      }
      &::after{
        outline: none;
      }
      background: white;
      border-radius: 0 10px 10px 0;
      padding-left: 20px;
      outline: none;
      width: 80%;
      & .ant-input-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        & .ant-input{
          color: #1F2833;
          padding: 0;
          width: 70%;
          padding: 5px 0;
          border: none;
        }
        & .ant-input-group-addon{
          margin: 0 0 0 1.5vw;
          border: none;
          .ant-btn{
            border: none;
          }
        }
      }
    }
    & .ant-dropdown-link{
      background-color: #C3073F;
      cursor: pointer;
      color: aliceblue;
      font-size: 12px;
      font-weight: 500;
      padding: 3px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 8vw;
      justify-content: space-between;
      border-radius: 10px 0 0 10px;
    }
    & .ant-typography{
      color: #950740;
    }
    & .ant-input-search{
      border-radius: 0 10px 10px 0;
      & .ant-input{
        color: #C3073F;
        padding: 0;
      }
    }
  }
  & .sierra-filter{
    width: 50%;
    display: flex;
    flex-direction: row;
    & .ant-input-search{
      &::before{
        outline: none;
      }
      &::after{
        outline: none;
      }
      background: white;
      border-radius: 0 10px 10px 0;
      padding-left: 20px;
      outline: none;
      width: 80%;
      & .ant-input-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        & .ant-input{
          color: #1F2833;
          padding: 0;
          width: 70%;
          padding: 5px 0;
          border: none;
        }
        & .ant-input-group-addon{
          margin: 0 0 0 1.5vw;
          border: none;
          .ant-btn{
            border: none;
          }
        }
      }
    }
    & .ant-dropdown-link{
      background-color: #42A29E;
      cursor: pointer;
      color: #C5C6C7;
      font-size: 12px;
      font-weight: 500;
      padding: 3px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 8vw;
      justify-content: space-between;
      border-radius: 10px 0 0 10px;
    }
    & .ant-typography{
      color: #1F2833;
    }
    & .ant-input-search{
      background: white;
      border-radius: 0 10px 10px 0;
      width: 80%;
      & .ant-input-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        & .ant-input{
          color: #1F2833;
          padding: 0;
          width: 70%;
          padding: 5px 0;
          border: none;
        }
        & .ant-input-group-addon{
          margin: 0 0 0 1.5vw;
          border: none;
          & .ant-btn{
            border: none;
          }
        }
      }
    }
  }
  @media (max-width: 440px) {
    &.default-filter{
      width: 70%;
      .ant-dropdown-link{
        width: 25vw;
      }
    }
    &.sierra-filter{
      width: 70%;
      .ant-dropdown-link{
        width: 25vw;
      }
    }
  }
  @media (max-width: 700px) {
    & .default-filter{
      width: 100%;
      & .ant-dropdown-link{
        width: 15vw;
      }
    }
    & .sierra-filter{
      width: 100%;
      & .ant-dropdown-link{
        width: 15vw;
      }
    }
  }
  @media (max-width: 1280px) {
    & .default-filter{
      & .ant-input-group-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    & .sierra-filter{
      & .ant-input-group-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
`