import { hot } from "react-hot-loader";
import React, { useEffect, useReducer, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { toast } from "react-toastify";

import AppContext from "./utils/context";
import AppReducer from "./utils/reducer";
import DashboardLanding from "./pages/landing.component";
import { initialState } from "./utils/initalState";
import { IUserDetails, IProfile } from "./utils/interfaces";
import {
  ADD_USER_DETAILS,
  TOGGLE_THEME,
  ADD_PROFILE_INFO,
  ADD_WINDOW_SIZE,
  ADD_BOOKINGS_COUNT,
} from "./utils/types";
import Login from "./pages/login.component";
import Verification from "./pages/verification.component";

import "typeface-roboto";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./ErrorPage";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const App: React.FC = () => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // * declare action creators by assigning action and value
  const addUserDetails = (value: IUserDetails) => {
    dispatch({
      type: ADD_USER_DETAILS,
      userDetails: value,
    });
  };

  const addBookingsCount = (value: any) => {
    dispatch({
      type: ADD_BOOKINGS_COUNT,
      bookingsCount: value,
    });
  };
  const addWindowSize = (value: any) => {
    dispatch({
      type: ADD_WINDOW_SIZE,
      windowSize: value,
    });
  };

  const toggleTheme = (value: string) => {
    dispatch({
      type: TOGGLE_THEME,
      theme: value,
    });
  };

  const addProfileInfo = (value: IProfile) => {
    dispatch({
      type: ADD_PROFILE_INFO,
      profileInfo: value,
    });
  };

  useEffect(() => {
    function handleWindowResize() {
      // setWindowSize(getWindowSize());
      let res = getWindowSize();
      addWindowSize(res);
      console.log("height", res.innerHeight);
      console.log("width", res.innerWidth);
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    // <Router history={history}>
    <AppContext.Provider
      value={{
        userDetails: state.userDetails,
        theme: state.theme,
        profileInfo: state.profileInfo,
        bookingsCount: state.bookingsCount,
        windowSize: state.windowSize,
        addUserDetails,
        toggleTheme,
        addProfileInfo,
        addBookingsCount,
      }}
    >
      <RouterProvider router={router}></RouterProvider>
      {/* <Switch> */}
      {/* <Route exact path='/login' component={Login} />
          <Route path='/' component={DashboardLanding}/>
          <Route path='/verify/:token' component={Verification}/> */}
      {/* </Switch> */}
    </AppContext.Provider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardLanding />,
    errorElement: <ErrorPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "verify/:id",
    element: <Verification />,
  },
]);

export default hot(module)(App);
