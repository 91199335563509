/** @jsx jsx */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import "./filters.styles.less";
import { Dropdown, Input, DatePicker } from "antd";
import { AiOutlineDown } from "react-icons/ai";
import { toast } from "react-toastify";
import type { MenuProps } from "antd";
import { useGetFilters } from "../../utils/customHooks";
import { filterOptions } from "../../utils/utilities";
import AppContext from "../../utils/context";
import { jsx } from "@emotion/react"
import { filterCss } from "./filters.styles";

const { Search } = Input;
const { RangePicker } = DatePicker;
// const { Title } = Typography;

type IFilterComponent = {
  theme: string;
  loading: boolean;
  nextPageNumber: number;
  searchQuery: string;
  setSearchQuery: (arg: string) => void;
  setLoading: (arg: boolean) => void;
  setPaidTickets: (arg: any) => void;
  setPaginationMetaData: (args: any) => void;
  setNextRolePage: (args: any) => void;
  setPaginationType: (args: string) => void;
};
///  name, phone, operator, confirmed, id, trade_id,
// payment_channel, booking_channel, booking_organization_id, /
// created_at, route

const Filters: React.FC<IFilterComponent> = ({
  theme,
  loading,
  nextPageNumber,
  searchQuery,
  setSearchQuery,
  setLoading,
  setPaidTickets,
  setPaginationMetaData,
  setNextRolePage,
  setPaginationType,
}) => {
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const [filterOption, setFilterOption] = useState("");
  const [filterIdx, setFilterIdx] = useState("1");
  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState([
    {
      key: "1",
      label: "Name",
    },
    {
      key: "2",
      label: "Trade Id",
    },
    {
      key: "3",
      label: "Operator",
    },
    {
      key: "4",
      label: "Booking Channel",
    },
    {
      key: "5",
      label: "Created at",
    },
    {
      key: "6",
      label: "Route",
    },
    {
      key: "7",
      label: "Date Range",
    },
  ])
  const [filterName, setFilterName] = useState([
    "Name",
    "Trade Id",
    "Operator",
    "Booking Channel",
    "Created at",
    "Route",
    "Date Range",
  ]);

  const [url, setUrl] = useState(
    `${
      process.env.REACT_APP_MARKETPLACE_URL
    }dashboard/bookings/?search=${searchQuery}&page=${nextPageNumber}&is_operator=${
      context.profileInfo.assigned_role === "easycoach_user"
        ? "easycoach"
        : "admin"
    }`
  );
  const { assigned_role: assignedRole } = context.profileInfo;
  const { state } = useGetFilters(url, searchQuery, nextPageNumber);

  useEffect(() => {
    if (context.profileInfo.assigned_role === "trips") {
      console.log('trips filter items')
      setItems([
        {
          key: "1",
          label: "Name",
        },
        {
          key: "2",
          label: "Trade Id",
        },
        {
          key: "5",
          label: "Created at",
        },
        {
          key: "7",
          label: "Date Range",
        },
      ])
    }
  }, [context.profileInfo.assigned_role])
  

  useEffect(() => {
    if (!searchQuery) return;
    if (state.status === "fetched") {
      let resBody = state.data.data;
      console.log(state.data.url, "cached url");
      console.log(state.data.data, "filter state data");
      if (state.data.url && state.data.url.includes("dashboard/date/range?")) {
        resBody = resBody.data;
      } else {
        resBody = state.data.data;
      }
      // console.log(resBody, "filter resBody");
      let parsedArray =
        resBody &&
        resBody.rows.map((ticketHolder: any, idx: Number) => {
          return (ticketHolder?.operator === "EasyCoach" ||
            ticketHolder?.operator === "Smart Coach") &&
            ticketHolder.sms
            ? {
                ...ticketHolder,
                key: idx,
                sms: ticketHolder.sms.message,
                sms_response: ticketHolder.sms_response?ticketHolder.sms_response.replace(/(^"|"$)/g, ""):'',
                name: ticketHolder.name
                  ? ticketHolder.name
                  : ticketHolder.passengers[0].full_name,
                departure_date: ticketHolder.departure_date
                  ? ticketHolder.departure_date.split("-").reverse().join("/")
                  : "",
                booking_date: ticketHolder.created_at
                  ? ticketHolder.created_at
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")
                  : "",
              }
            : {
                ...ticketHolder,
                key: idx,
                name: ticketHolder.name
                  ? ticketHolder.name
                  : ticketHolder.passengers[0].full_name,
                departure_date: ticketHolder.departure_date
                  ? ticketHolder.departure_date.split("-").reverse().join("/")
                  : "",
                booking_date: ticketHolder.created_at
                  ? ticketHolder.created_at
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")
                  : "",
              };
        });
      console.log(parsedArray, "parsed array");
      setPaginationMetaData(resBody.paginationMeta.totalPages);
      setPaidTickets(parsedArray);
      setLoading(false);
    } else if (state.status === "fetching") {
      console.log(state.data, "filter fetching");
    } else if (state.status === "idle") {
    } else {
      setLoading(false);
      console.log(state.error, "error fetching search query");
      toast.error("Search failed, please try again", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 1000,
      });
    }
    return () => {};
  }, [state, searchQuery, setPaginationMetaData, setPaidTickets, setLoading]);

  const handleSearch = useCallback(
    (value) => {
      setLoading(true);
      setPaginationType("search");
      console.log("search value", value);
      setSearchQuery(value);
      setUrl(
        `${
          process.env.REACT_APP_MARKETPLACE_URL
        }dashboard/bookings/?search=${value}&is_operator=${
          assignedRole === "easycoach_user"
            ? "easycoach"
            : assignedRole === "smartcoach_user"
            ? "smartcoach"
            : assignedRole === "trips_customer_care"
            ? ""
            : "admin"
        }&page=${nextPageNumber}`
      );
    },
    [
      setSearchQuery,
      setLoading,
      setUrl,
      assignedRole,
      nextPageNumber,
      setPaginationType,
    ]
  );


  const handleDate = useCallback(
    (date ,dateStr) => {
      setLoading(true);
      console.log('created on date change', dateStr);
      setPaginationType("search");
      let constructedUrl = `${process.env.REACT_APP_MARKETPLACE_URL}`+
        `dashboard/date/range?start_departure_date=${dateStr}&end_departure_date=${dateStr}`+
        `&is_customer_care=is_customer_care&page=${nextPageNumber}&is_operator=${assignedRole === "easycoach_user"
            ? "easycoach"
            : assignedRole === "smartcoach_user"
            ? "smartcoach"
            : "admin"
        }`
        console.log('constructed url', constructedUrl);
      setSearchQuery(dateStr);
      setUrl(constructedUrl);
    },
    [
      setLoading,
      setUrl,
      setSearchQuery,
      assignedRole,
      nextPageNumber,
      setPaginationType,
    ]
  );

  const handleDateRange = useCallback(
    (dateMoment, dateStr) => {
      setLoading(true);
      setPaginationType("search");
      
      let constructedDate = dateStr.join(" ");
      console.log('date range constructedDate', constructedDate);
      setSearchQuery(constructedDate);

      setUrl(
        `${
          process.env.REACT_APP_MARKETPLACE_URL
        }dashboard/date/range?start_departure_date=${
          dateStr[0]
        }&end_departure_date=${
          dateStr[1]
        }&is_customer_care=is_customer_care&page=${nextPageNumber}&is_operator=${
          assignedRole === "easycoach_user"
            ? "easycoach"
            : assignedRole === "smartcoach_user"
            ? "smartcoach"
            : "admin"
        }`
      );
    },
    [
      setLoading,
      setUrl,
      setSearchQuery,
      assignedRole,
      nextPageNumber,
      setPaginationType,
    ]
  );

  const handleVisibleChange = useCallback((flag) => {
    setVisible(flag);
  }, []);

  const handleClear = useCallback(() => {
    setSearchQuery("");
    setPaidTickets([]);
    setPaginationType("normal");
  }, [setSearchQuery, setPaidTickets, setPaginationType]);


  const onClick: MenuProps["onClick"] = (e) => {
    setVisible(false);
    console.log("menu select", e);
    setFilterOption(filterName[Number(e.key) - 1]);
    setFilterIdx(e.key);
  };

  return (
    <div css={filterCss}>
      <div
        className={
          theme === "Sierra"
            ? "sierra-filter"
            : "default-filter"
        }
      >
        <Dropdown
          menu={{ items, onClick }}
          onOpenChange={(flag) => handleVisibleChange(flag)}
          open={visible}
          overlayStyle={{
            padding: "0",
          }}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            {filterOption ? filterOption : "Choose Filter"} <AiOutlineDown />
          </div>
        </Dropdown>
        {filterOptions["name"] === filterIdx ? (
          <Search
            placeholder="Name"
            onSearch={(value) => handleSearch(value)}
            style={{ width: 200 }}
          />
        ) : filterOptions["operator"] === filterIdx ? (
          <Search
            placeholder="Operator"
            onSearch={(value) => handleSearch(value)}
            style={{ width: 200 }}
          />
        ) : filterOptions["tradeId"] === filterIdx ? (
          <Search
            placeholder="Trade Id"
            onSearch={(value) => handleSearch(value)}
            style={{ width: 200 }}
          />
        ) : filterOptions["bookingChannel"] === filterIdx ? (
          <Search
            placeholder="Booking Channel"
            onSearch={(value) => handleSearch(value)}
            style={{ width: 200 }}
          />
        ) : filterOptions["createdAt"] === filterIdx ? (
          // <Search
          //   placeholder="Created At"
          //   onSearch={(value) => handleSearch(value)}
          //   style={{ width: 200 }}
          // />
          <DatePicker 
            onChange={(date, dateStr) => handleDate(date, dateStr)} 
            placeholder="Created At"
            style={{ width: 200 }}
          />
        ) : filterOptions["route"] === filterIdx ? (
          <Search
            placeholder="Route"
            onSearch={(value) => handleSearch(value)}
            style={{ width: 200 }}
          />
        ) : filterOptions["dateRange"] === filterIdx ? (
          <RangePicker
            onChange={(dateMom, dateStr) => handleDateRange(dateMom, dateStr)}
            style={{ width: 200 }}
          />
        ) : (
          <Search
            placeholder="Enter Filter"
            onSearch={(value) => handleSearch(value)}
            style={{ width: 200 }}
          />
        )}
      </div>
      {searchQuery ? (
        <div onClick={(e) => handleClear()} className="delete-icon">
          Clear Filter
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Filters;
