/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import LoginForm from "../components/login/login-form.component";

import Registration from "../components/registration/registration.component";
import { css, jsx } from "@emotion/react";

const Logo = require("../imgs/logo.svg");

const EasycoachLogo = require("../imgs/easycoach_logo.jpeg");
const TripsLogo = require("../imgs/trips_logo.svg");
const SmartcoachLogo = require("../imgs/smartcoach_logo.webp");

const { Content } = Layout;

const Login = () => {
  const [signedIn, setSignedIn] = useState("");
  const [userRole, setUserRole] = useState<any>("");

  useEffect(() => {
    let role = localStorage.getItem("role")
      ? localStorage.getItem("role")
      : "normal";
     console.log('login role', role)
    setUserRole(role);
    return () => {};
  }, []);

  return (
      <Layout 
        style={{ minHeight: "100vh" }} 
        css={css`background-color: white;`}
      >
        <Content style={{ padding: "0 50px" }} 
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
        >
          <img
            src={
              ( userRole === "normal" ||
                userRole === "bp_customer_care" ||
                userRole === "operations" ||
                userRole === "accounts"
              )
                ? Logo
                : userRole === "easycoach_user"
                ? EasycoachLogo
                : userRole === "smartcoach_user"
                ? SmartcoachLogo
                : userRole === "trips"
                ? TripsLogo
                : ""
            }
            css={css`
              width: 8rem;
              height: auto;
              margin-bottom: .5rem;
            `}
            alt="buupass-logo"
            className="logo-img"
          />
          <div className="login-content">
            {signedIn === "sign-up" ? (
              <Registration setSignedIn={setSignedIn} />
            ) : (
              <LoginForm setSignedIn={setSignedIn} />
            )}
          </div>
        </Content>
      </Layout>
  );
};

export default Login;
