import { css } from "@emotion/react";

export const landingCss = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 3rem;
  width: 100%;
  overflow-x: hidden;
  align-items: center;
  .data-row {
    display: flex;
    margin-top: 15px;
    .total-card {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 20%;
      padding: 10px;
      span:first-of-type {
        font-size: 12px;
      }
      span:last-of-type {
        font-size: 20px;
      }
    }
  }
  &.default-table {
    table {
      tr {
        th {
          font-weight: 700;
          border: 1px solid #4e4e50;
        }
        td {
          border: 1px solid #4e4e50;
          color: #000c17;
          padding: 15px;
        }
      }
    }
  }

  &.sierra-table {
    table {
      tr {
        th {
          font-weight: 700;
          color: #c5c6c7;
          border: 1px solid #4e4e50;
        }
        td {
          border: 1px solid #4e4e50;
          color: #c5c6c7;
          padding: 15px;
        }
      }
    }
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
    margin: 0 0 20px 0;
    .delete-icon {
      color: aliceblue;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      border-radius: 10px;
      width: 8vw;
      padding: 10px;
      background-color: #950740;
    }
    .filters {
      width: 50%;
      display: flex;
      flex-direction: row;
      // .ant-input-search{
      //   padding: 5px;
      //   display: flex;
      //   align-items: center;
      //   flex-direction: row;
      //   justify-content: space-between;
      // }
      .ant-input-search {
        &::before {
          outline: none;
        }
        &::after {
          outline: none;
        }
        background: white;
        border-radius: 0 10px 10px 0;
        padding-left: 20px;
        outline: none;
        width: 100%;
        .ant-input-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          .ant-input {
            color: #1f2833;
            padding: 0;
            width: 70%;
            padding: 5px 0;
            border: none;
          }
          .ant-input-group-addon {
            margin: 0 0 0 1.5vw;
            border: none;
            .ant-btn {
              border: none;
            }
          }
        }
      }
      &.default-filter {
        .ant-dropdown-link {
          background-color: #c3073f;
          cursor: pointer;
          color: aliceblue;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 8vw;
          justify-content: space-between;
          border-radius: 10px 0 0 10px;
        }
        .ant-typography {
          color: #950740;
        }
        .ant-input-search {
          border-radius: 0 10px 10px 0;
          .ant-input {
            color: #c3073f;
            padding: 0;
          }
        }
      }
      &.sierra-filter {
        .ant-dropdown-link {
          background-color: #42a29e;
          cursor: pointer;
          color: #c5c6c7;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 8vw;
          justify-content: space-between;
          border-radius: 10px 0 0 10px;
        }
        .ant-typography {
          color: #1f2833;
        }
        .ant-input-search {
          background: white;
          border-radius: 0 10px 10px 0;
          width: 80%;
          .ant-input-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            .ant-input {
              color: #1f2833;
              padding: 0;
              width: 70%;
              padding: 5px 0;
              border: none;
            }
            .ant-input-group-addon {
              margin: 0 0 0 1.5vw;
              border: none;
              .ant-btn {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

.action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .action-button {
    border: 2px solid #6f2232;
    text-align: center;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    font-weight: 500;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 700px) {
  .paid-bookings {
    .ant-table-wrapper {
      width: 95%;
      .ant-spin-nested-loading {
        .ant-spin-container {
          position: relative;
          .ant-table {
            .ant-table-container {
              .ant-table-header {
                font-size: 1em;
                .ant-table-cell {
                  border: none;
                }
                // .ant-table-cell-fix-left{
                // }
                // .ant-table-cell-fix-right-first{
                // }
              }
              .ant-table-body {
                font-size: 0.9em;
                .ant-table-tbody {
                  tr {
                    height: 70%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`