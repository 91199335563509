import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100vh',
      alignItems: 'center'
    }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p style={{
        fontWeight: 'bolder'
      }}>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </div>
  );
}