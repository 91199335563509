import React, { useContext, useEffect, useRef, useState } from "react";
import AddUsers from "../add-users/add-users.component";
import UnconfirmedBookings from "../unconfirmed-bookings/unconfirmed-bookings.component";
import AllBookings from "../all-bookings/all-bookings.component";
import TodayBookings from "../today-bookings/today-bookings.component";
import YesterdayBookings from "../yesterday-bookings/yesterday-bookings.component";
import Profile from "../profile/profile.component";
import Analytics from "../analytics/analytics.component";
import Roles from "../roles/roles.component";
import LastWeekBookings from "../last-week-bookings/last-week-bookings.component";
import PaidBookings from "../paid-bookings/paid-bookings.component";
import EasyCoachLanding from "../easy-coach-landing/easy-coach-landing.component";
import TripsLanding from "../trips-landing/trips-landing.component";
import PaidNoTickets from "../paid-no-tickets/paid-no-tickets.component";
import PaidNoPnr from "../paid-no-pnr/paid-no-pnr.component";
import { useGetEasycoachBookings } from "../../utils/customHooks";
import AppContext from "../../utils/context";

export interface IContentForm {
  contentForm: number;
  theme: string;
  userDetails: {};
}

const ContentContainer: React.FC<IContentForm> = ({
  contentForm,
  theme,
  userDetails,
}) => {
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const [nextRolePage, setNextRolePage] = useState(1);
  const { assigned_role: assignedRole } = context.profileInfo;

  // side effect on table pagination

  // useEffect(() => {
  //   console.log('profileInfo --> main-content', context.profileInfo)
  //   if (state.status === "fetched") {
  //     contextRef.current.addBookingsCount(state.data.bookings_count);

  //   } else if (state.status === "fetching") {
  //   } else if (state.status === "idle") {
  //   } else {
  //   }
  //   return () => {};
  // }, [state, nextRolePage]);

  switch (contentForm) {
    case 1:
      return <AddUsers theme={theme} />;
    case 2:
      return <TodayBookings theme={theme} />;
    case 3:
      return <UnconfirmedBookings theme={theme} />;
    case 4:
      return <AllBookings theme={theme} />;
    case 5:
      return <YesterdayBookings theme={theme} />;
    case 6:
      return <Profile theme={theme} />;
    case 7:
      return <Analytics theme={theme} />;
    case 8:
      return <Roles theme={theme} />;
    case 9:
      return <LastWeekBookings theme={theme} />;
    case 10:
      return <PaidNoTickets theme={theme} userDetails={userDetails} />;
    case 11:
      return <PaidBookings 
        theme={theme} 
        userDetails={userDetails} 
      />;
    case 12:
      return <PaidNoPnr theme={theme} />;
    case 13:
      return <EasyCoachLanding 
        theme={theme}
        nextRolePage={nextRolePage}
      />;
    case 14:
      return <TripsLanding 
        theme={theme}
        nextRolePage={nextRolePage}
      />;
    default:
      return (
        <div>content loading...</div>
      );
  }
};

export default ContentContainer;
