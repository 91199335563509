import React, { useState, useEffect, useCallback, useContext }  from 'react'
import { Table } from 'antd'
import { paidBookingsNoTicketColumnData } from '../../utils/initialData';

import './last-week-bookings.styles.less'
import Axios from 'axios';
import { toast } from 'react-toastify';
import { IThemeProps } from '../yesterday-bookings/yesterday-bookings.component';
import AppContext from '../../utils/context';

const LastWeekBookings: React.FC<IThemeProps> = ({ theme }) => {
  const [columns, setColumns] = useState<any>([]);
  const [paidTickets, setPaidTickets] = useState([]);
  const [paginationMetaData, setPaginationMetaData] = useState(0);
  const [nextRolePage, setNextRolePage] = useState(1);
  const context = useContext(AppContext);

  const fetchPaidNoTickets = useCallback(async () => {
    await Axios.get(
      `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/ticket/unsent?is_customer_care=is_customer_care&week=week&page=${nextRolePage}`,
      {
        headers: { Authorization: "Bearer " + context.userDetails.data.token }
      }
    ).then((data) => {
      let resBody = data.data.data.data; 
      console.log(resBody, 'last week bookings fetched');
      let parsedArray = resBody.rows.map((ticketHolder: object, idx: Number) => {
        return { ...ticketHolder, key: idx }
      })
      console.log(parsedArray, 'parsedArray');
      setPaginationMetaData(resBody.paginationMeta.totalPages);
      setPaidTickets(parsedArray)
      
    }).catch(error => {
      console.log(error.response, 'error fetching paid no ticket bookings');
      toast.error(error.response.data.detail,  {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 1000,
      });
    })
  }, [nextRolePage]);

  useEffect(() => {
    setColumns([...paidBookingsNoTicketColumnData]);
  }, [])

  useEffect(() => {
    if(nextRolePage >= 1){
      fetchPaidNoTickets()
    }
    return () => {

    }
  }, [nextRolePage, fetchPaidNoTickets])
  return (
    <div className= {`last-week-bookings ${theme === 'Mojave'? 'mojave-table': (theme === 'Sierra'? 'sierra-table': 'default-table')}`}>
      {paginationMetaData ? (<Table 
        columns={columns}
        dataSource={paidTickets} 
        scroll={{ x: 1200, y: 600 }}
        pagination={{
          onChange: (page) => { setNextRolePage(page)},
          total: (paginationMetaData*10)-1
        }} 
      />) : 'loading...'}
    </div>
  )
}

export default LastWeekBookings

