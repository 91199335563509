/** @jsx jsx */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { ISignUpProps } from "../registration/registration.component";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { usePostAxios } from "../../utils/customHooks";
import AppContext from "../../utils/context";
import { jsx } from "@emotion/react";

import { loginCss } from "./login-form.styles";

const LoginForm: React.FC<ISignUpProps> = ({ setSignedIn }) => {
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const [payload, setPayload] = useState();
  const navigate = useNavigate();
  const { state: loginState } = usePostAxios(
    `${process.env.REACT_APP_USER_URL}/users/login`,
    // 'https://dev.usermanagementapi.buupass.com/user-management/users/login',
    payload
  );

  const onFinish = (values: any) => {
    setPayload(values);
  };

  useEffect(() => {
    if (loginState.status === "fetched") {
      console.log("login data", loginState);
      localStorage.setItem("token", loginState.data.token);
      toast.success("You have successfully logged in!",{
        autoClose: 1000,
      });
      contextRef.current.addUserDetails(loginState);
      setTimeout(() => {
        navigate("/");
      }, 800);
    } else if (loginState.status === "fetching") {
      console.log("fetching", loginState.data);
      toast.success("logging into dashboard...",{
        autoClose: 4000,
      });
    } else if (loginState.status === "idle") {
      console.log("idle", loginState.data);
      console.log("payload", payload);
    } else if (loginState.status === "error") {
      toast.error(loginState.error, {
        autoClose: 1000
      });
      console.log("error", loginState);
    } else {
    }
  }, [loginState, payload, navigate]);

  return (
    <div css={loginCss}>
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="email"
            placeholder="joe@buupass.com"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <span></span>
          {/* <span
            className="login-form-forgot"
            onClick={() => setSignedIn("sign-up")}
          >
            Request New Account
          </span> */}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button login-button-effect"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
      <ToastContainer 
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default LoginForm;
