import { IThemeProperties } from "./interfaces";

export default class Utilities {
  static convertToInt = (value: string): number => {
    return value === "default" ? 1 : 2;
  };
  static isEmpty = (testObject: object) => {
    for (let key in testObject) {
      if (testObject.hasOwnProperty(key)) return false;
    }
    return true;
  };
  static objectToArray = (value: any) => {
    let newArray = [];
    for (let i = 0; i < value.length; i++) {
      if (typeof value[i] === "object") {
        newArray.push(value[i].Name);
      } else {
        newArray.push(value[i]);
      }
    }
    return newArray;
  };
  static isArrayEmpty = (value: any) => {
    if (value && value.constructor === Array && value.length === 0) {
      return false;
    } else {
      return true;
    }
  };
}

export const themeColorPalettes: IThemeProperties = {
  Sierra: {
    backgroundColor: "#1F2833",
    textColor: "#C5C6C7",
    iconColor: "#66FCF1",
    sideBarColor: "#0B0C10",
    extraColor: "#42A29E",
    headerTextColor: "#66FCF1",
  },
  default: {
    backgroundColor: "#e0e0e0",
    textColor: "#4E4E50",
    iconColor: "#ffffff",
    sideBarColor: "#950740",
    sideBarColorHover: "#C3073F",
    extraColor: "#6F2232",
    headerTextColor: "#000c17",
  },
  Mojave: {
    backgroundColor: "#EFE2BA",
    textColor: "#4056A1",
    iconColor: "#C5CBE3",
    sideBarColor: "#D79922",
    extraColor: "#F13C20",
    headerTextColor: "#66FCF1",
  },
};

export const contentPages: any = {
  addUsers: 1,
  todayBookings: 2,
  unconfirmedBookings: 3,
  allBookings: 4,
  yesterDayBookings: 5,
  profile: 6,
  analysis: 7,
  roles: 8,
  lastWeekBookings: 9,
  paidNoTickets: 10,
  paidBookings: 11,
  paidNoPnr: 12,
  easyCoachLanding: 13,
  tripsLanding: 14,
};

export const filterOptions: any = {
  name: "1",
  tradeId: "2",
  operator: "3",
  bookingChannel: "4",
  createdAt: "5",
  route: "6",
  dateRange: "7",
};
