/** @jsx jsx */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Table, Descriptions, Space, Button, Typography } from "antd";
import { paidBookingsColumnData } from "../../utils/initialData";
import Filters from "../filters/filters.component";
import "./unconfirmed-bookings.style.less";
import Axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IThemeProps } from "../yesterday-bookings/yesterday-bookings.component";
import { useProfileAxios } from "../../utils/customHooks";
import { themeColorPalettes } from "../../utils/utilities";
import { AiOutlineClose } from "react-icons/ai";
import AppContext from "../../utils/context";

import { jsx } from "@emotion/react";

import { 
  actionBtnsCss, 
  actionLightBtnsCss, 
  paidBookingsCss 
} from "../paid-bookings/paid-bookings.styles";

const { Item } = Descriptions;
const { Title } = Typography;

const UnconfrimedBookings: React.FC<IThemeProps> = ({ theme }) => {
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const [columns, setColumns] = useState<any>([]);
  const [paidTickets, setPaidTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationType, setPaginationType] = useState("normal");
  const [paginationMetaData, setPaginationMetaData] = useState(0);
  const [nextRolePage, setNextRolePage] = useState(1);
  const [showManifest, setShowManifest] = useState(false);
  const [manifestData, setManifestData] = useState<any>({});
  const navigate = useNavigate();

  const { assigned_role: assignedRole } = context.profileInfo;
  const { innerWidth, innerHeight } = context.windowSize;

  const { state: profileState } = useProfileAxios(
    `${process.env.REACT_APP_USER_URL}/users/profile`
  );

  useEffect(() => {
    if (profileState.status === "fetched") {
      console.log("profile fetched", profileState.data);
    } else if (profileState.status === "fetching") {
      console.log("profile fetching", profileState.data);
    } else if (profileState.status === "idle") {
      console.log("profile idle", profileState.data);
    } else {
      console.log("profile error", profileState.data);
      navigate("/login");
    }
  }, [profileState, navigate]);

  const fetchUnconfirmedBookings = useCallback(async () => {
    setLoading(true)
    await Axios.get(
      `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/ticket/unsent?is_customer_care=is_customer_care&is_operator=${
        assignedRole === "easycoach_user"
          ? "easycoach"
          : assignedRole === "smartcoach_user"
          ? "smartcoach"
          : "admin"
      }&unconfirmed_bookings=unconfirmed_bookings&page=${nextRolePage}`,
      {
        headers: {
          Authorization: "Bearer " + context.userDetails.data.token
        },
      }
    )
      .then((data) => {
        let resBody = data.data.data.data;
        console.log(resBody, "unconfirmed bookings fetched");
        let parsedArray = resBody.rows.map((ticketHolder: any, idx: Number) => {
          return {
            ...ticketHolder,
            key: idx,
            departure_date: ticketHolder.departure_date
              ? ticketHolder.departure_date.split("-").reverse().join("/")
              : "",
            booking_date: ticketHolder.created_at
              ? ticketHolder.created_at
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/")
              : "",
          };
        });
        console.log(parsedArray, "parsedArray");
        setPaginationMetaData(resBody.paginationMeta.totalPages);
        setPaidTickets(parsedArray);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response, "error fetching paid no ticket bookings");
        toast.error(error.response.data.detail, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: 5000,
        });
        setLoading(false)
      });
  }, [nextRolePage]);

  useEffect(() => {
    if (nextRolePage >= 1) {
      fetchUnconfirmedBookings();
    }
    return () => {};
  }, [nextRolePage, fetchUnconfirmedBookings]);

  const handleViewManifest = (e: any) => {
    // e.persist();
    console.log("handleViewManifest", e);
    let manifestData = e.departure_time?.split(":");
    manifestData = manifestData?.slice(0, manifestData.length - 1).join(":");
    manifestData = {
      ...e,
      departure_time: manifestData,
    };
    setShowManifest(true);
    setManifestData(manifestData);
  };

  useEffect(() => {
    let columnInfo = [];
    if (context.profileInfo.assigned_role === "easycoach_user") {
      columnInfo = paidBookingsColumnData.filter((item: any, idx: number) => item.key !== "operator");
      columnInfo = columnInfo.filter((item: any, idx: number) => item.key !== "sms");
      columnInfo = columnInfo.filter((item: any, idx: number) => item.key !== "booking_channel");
      
      columnInfo.unshift({
        title: "Booking Date",
        dataIndex: "booking_date",
        key: "booking_date",
        width: 50,
        fixed: "left",
        ellipsis: true,
      });
    } else {
      columnInfo = paidBookingsColumnData;
    }
    setColumns([
      ...columnInfo,
      {
        title: "Action",
        key: "action",
        width: 80,
        fixed: "right",
        render: (item: any) => (
          <div css={
            theme === "default"
            ? actionLightBtnsCss
            : actionBtnsCss
          }>
            <span
              className="action-button"
              onClick={() => handleViewManifest(item)}
            >
              VIEW MANIFEST
            </span>
          </div>
        ),
      },
    ]);
  }, []);

  return (
    <div
      css={paidBookingsCss}
    >
      <Filters
        theme={theme}
        setLoading={setLoading}
        loading={loading}
        nextPageNumber={nextPageNumber}
        searchQuery={searchQuery}
        setNextRolePage={setNextRolePage}
        setSearchQuery={setSearchQuery}
        setPaidTickets={setPaidTickets}
        setPaginationMetaData={setPaginationMetaData}
        setPaginationType={setPaginationType}
      />
      {showManifest ? (
        <Descriptions
          title={
            <Space
              size={"large"}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Space
                size={"large"}
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Title
                  type="secondary"
                  level={5}
                  style={{
                    color:
                      theme === "Sierra"
                        ? themeColorPalettes[theme].textColor
                        : themeColorPalettes[theme].headerTextColor,
                  }}
                >
                  MANIFEST DATA
                </Title>
              </Space>
              <Button
                shape="circle"
                onClick={() => setShowManifest(false)}
                icon={<AiOutlineClose />}
              ></Button>
            </Space>
          }
          style={{
            maxWidth: "95%",
          }}
          contentStyle={{
            backgroundColor:
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor,
          }}
          // size='middle'
          bordered
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <Item label="PNR">
            {manifestData.operator === "EasyCoach"
              ? manifestData.confirmation_response
                ? manifestData.confirmation_response[0]
                : ""
              : manifestData.operator === "Moderncoast"
              ? manifestData.confirmation_response.ticket_references
              : manifestData.confirmation_response?.confirmation_responses[0]}
          </Item>
          <Item label="Departure">{manifestData.source}</Item>
          <Item label="Destination">{manifestData.destination}</Item>
          <Item label="BKT Amount">{manifestData.total_amount}</Item>
          <Item label="Travel Date">{manifestData.departure_date}</Item>
          <Descriptions.Item label="Trip Type">
            <span
              style={{
                backgroundColor: themeColorPalettes[theme].backgroundColor,
                color: themeColorPalettes[theme].textColor,
                padding: "10px",
                borderRadius: "6px",
              }}
            >
              {manifestData.is_return ? "Return Trip" : "Single Trip"}
            </span>
          </Descriptions.Item>
          <Item label="Departure Time">{manifestData.departure_time}</Item>
          <Item label="Seat No.">{manifestData?.seats[0]}</Item>
          <Item label="Passenger Name">
            {manifestData?.passengers[0].full_name}
          </Item>
          <Item label="Route Name">{manifestData.route}</Item>
          <Item label="Passenger Id">
            {manifestData?.passengers[0].id_or_passport}
          </Item>
          <Item label="Operator">{manifestData.operator}</Item>
        </Descriptions>
      ) : paginationMetaData ? (
        <Table
          columns={columns}
          dataSource={paidTickets}
          loading={loading}
          size="small"
          virtual
          scroll={{
            x: innerWidth * 0.8,
            y:
              innerHeight > 750
                ? innerHeight * 0.7
                : innerHeight > 500
                ? innerHeight * 0.6
                : innerHeight * 0.4,
          }}
          pagination={{
            onChange: (page) => {
              if (paginationType === "search") {
                setNextPageNumber(page);
              } else {
                setNextRolePage(page);
              }
            },
            total: paginationMetaData * 10 - 1,
          }}
        />
      ) : (
        "loading..."
      )}
    </div>
  );
};

export default UnconfrimedBookings;
