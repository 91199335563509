/** @jsx jsx */
import React, { useState, useEffect, useContext } from "react";
import { Button, DatePicker, Descriptions, Space, Spin, Typography } from "antd";
import { landingCss } from "./trips-landing.styles";
import { useTripsAccount } from "../../utils/customHooks";
import { useNavigate } from "react-router-dom";
import AppContext from "../../utils/context";
import { css, jsx } from "@emotion/react";
import { themeColorPalettes } from "../../utils/utilities";

const { RangePicker } = DatePicker;

const { Title } = Typography;

const TripsLanding: React.FC<any> = ({ theme, nextRolePage }) => {
  const context = useContext(AppContext);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalData, setTotalData] = useState<any>([]);
  const navigate = useNavigate();
  const { assigned_role: assignedRole } = context.profileInfo;

  // const { state: profileState } = useProfileAxios(
  //   `${process.env.REACT_APP_USER_URL}/users/profile`
  // )
  useEffect(() => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    let todayDate = dd + "/" + mm + "/" + yyyy;
    setFromDate(todayDate);
    return () => {};
  }, []);

  const { state: landingState } = useTripsAccount();
  // const { state } = useGetPaidBookings(
  //   `${
  //     process.env.REACT_APP_MARKETPLACE_URL
  //   }dashboard/ticket/unsent?is_customer_care=is_customer_care&is_operator=${
  //     assignedRole === "easycoach_user"
  //       ? "easycoach"
  //       : assignedRole === "smartcoach_user"
  //       ? "smartcoach"
  //       : "admin"
  //   }&paid_with_tickets=paid_with_tickets&page=`,
  //   nextRolePage
  // );

  useEffect(() => {
    if (landingState.status === "fetched") {
      console.log("landing fetched", landingState.data);
      let parsedObj: any = [];
      // if (
      //   typeof landingState.data[0] === "object" &&
      //   !Array.isArray(landingState.data[0]) &&
      //   landingState.data[0] !== null
      // ) {
      //   parsedObj = landingState.data[1];
      // } else {
      //   parsedObj = landingState.data[0][1];
      // }

      // parsedObj = Object.entries(parsedObj);

      // setTotalData(parsedObj);
    } else if (landingState.status === "fetching") {
      // console.log("landing fetching", landingState.data);
    } else if (landingState.status === "idle") {
      // console.log("landing idle", landingState.data);
    } else {
      console.log("landing error", landingState.data);
      // navigate("/login");
    }
  }, [landingState, navigate]);

  return (
    <div
      css={landingCss}
      className={`${
        theme === "Sierra"
          ? "sierra-table"
          : "default-table"
      }`}
    >
      {landingState.data.status ? (
        // <table>
        //   <thead>
        //     <tr>
        //       <th>Agent</th>
        //       <th>Balance</th>
        //       <th>Total Transactions</th>
        //       <th>Usage Amount</th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     <tr>
        //       <td>{landingState.data.data.data.agent}</td>
        //       <td>{landingState.data.data.data.balance}</td>
        //       <td>{landingState.data.data.data.total_transactions}</td>
        //       <td>{landingState.data.data.data.usage_amount}</td>
        //     </tr>
        //   </tbody>
        // </table>
        <Descriptions
          title={
            <Space
              size={"large"}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Space
                size={"large"}
                style={{
                  flex: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Title
                  type="secondary"
                  level={5}
                  style={{
                    color:
                      theme === "Sierra"
                        ? themeColorPalettes[theme].textColor
                        : themeColorPalettes[theme].headerTextColor,
                  }}
                >
                  ACCOUNT DETAILS
                </Title>
                <span></span>
              </Space>
              <span></span>
            </Space>
          }
          style={{
            maxWidth: "95%",
          }}
          contentStyle={{
            backgroundColor:
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor,
            color: theme === "Sierra"
              ? themeColorPalettes[theme].sideBarColor
              : themeColorPalettes[theme].headerTextColor,
          }}
          labelStyle={{
            color: theme === "Sierra"
            ? themeColorPalettes[theme].textColor
            : themeColorPalettes[theme].headerTextColor,
          }}
          // size='middle'
          bordered
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="Account owner">
            {landingState.data.data.data.agent}
          </Descriptions.Item>
          <Descriptions.Item label="Account balance">
            {landingState.data.data.data.balance}
          </Descriptions.Item>
          <Descriptions.Item label="Total Transactions">
            {landingState.data.data.data.total_transactions}
          </Descriptions.Item>
          <Descriptions.Item label="Usage Amount">
            {landingState.data.data.data.usage_amount}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <div><Spin size="large"></Spin></div>
      )}
    </div>
  );
};

export default TripsLanding;
