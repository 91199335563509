import { css } from "@emotion/react";

export const sideBarCss = css`
.ant-layout-sider {
  .ant-layout-sider-children {
    margin-left: 5px;
    width: 100%;
    .ant-menu {
      .ant-menu-item-selected {
        color: @dark-navy;
      }
      .ant-menu-item {
        position: relative;
        padding-left: 5% !important;
        :nth-of-type(1) {
          font-size: 1.2em;
        }
        :nth-of-type(2) {
          padding: 10px;
          font-size: 12px;
        }
        &.ant-menu-item-active {
          position: relative;
          padding: 10px;
          background-color: #c5cbe3 !important;
          color: #0b0c10 !important;
          :nth-of-type(1) {
            font-size: 1.2em;
          }
          :nth-of-type(2) {
            padding: 0 10px;
            font-size: 12px;
          }
        }
      }
    }
    // .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    //   background-color: #eb712f;
    // }
    &.mojave-theme {
      .ant-menu {
        width: 60%;
        .ant-menu-submenu {
          .ant-menu {
            background-color: #d79922;
            .ant-menu-item {
              background-color: #d79922;
            }
          }
        }
      }
    }
    &.sierra-theme {
      .ant-menu {
        width: 60%;
        .ant-menu-submenu {
          .ant-menu {
            background-color: #0b0c10;
            .ant-menu-item {
              background-color: #0b0c10;
            }
          }
        }
      }
    }
    &.default-theme {
      .ant-menu {
        width: 60%;
        .ant-menu-submenu {
          .ant-menu {
            background-color: #000c17;
            .ant-menu-item {
              background-color: #000c17;
            }
          }
        }
      }
    }
    .trigger {
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 1.8em;
      color: white;
      &.trigger-marginLeft-shrink {
        transition: fadeIn 0.3s;
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 1351px) {
  .ant-layout-sider-children {
    transform: translateX(-50px);
  }
}

// Sierra: {
//   backgroundColor: '#1F2833',
//   textColor: '#C5C6C7',
//   iconColor: '#66FCF1',
//   sideBarColor: '#0B0C10',
//   extraColor: '#42A29E',
//   headerTextColor:'#66FCF1',
// },
// default: {
//   backgroundColor: '#1A1A1D',
//   textColor: '#4E4E50',
//   iconColor: '#C3073F',
//   sideBarColor: '#000c17',
//   sideBarColorHover: '#6F2232',
//   extraColor: '#950740',
//   headerTextColor: '#ffffff'
// },
// Mojave: {
//   backgroundColor: '#EFE2BA',
//   textColor: '#4056A1',
//   iconColor: '#C5CBE3',
//   sideBarColor: '#D79922',
//   extraColor: '#F13C20',
//   headerTextColor:'#66FCF1'
// }

`