import React from 'react'
import { Layout, Form, Input, InputNumber, Button } from 'antd';

import './add-users.styles.less';
import { themeColorPalettes } from '../../utils/utilities';

const { Content } = Layout;

export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: 'This field is required!',
  types: {
    email: 'Not a valid email!',
    number: 'Not a valid number!',
  },
  number: {
    range: 'Must be a number',
  },
};

export interface IProps{
  theme: string;
}

const AddUsers: React.FC<IProps> = ({ theme }) => {
  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <Content>
      <div 
        className="site-layout-background"
        style={{ 
          padding: 24,
          minHeight: 360,
          backgroundColor: themeColorPalettes[theme? theme: 'default'].textColor,
        }}>
        <Form 
          {...layout} 
          name="nest-messages" 
          onFinish={onFinish} 
          validateMessages={validateMessages}
          className={`${theme === 'default'? 'label-styling': ((theme === 'Mojave')? 'mojave-styling':'')}`}
        >
          <Form.Item 
            name={['user', 'first_name']} 
            label="First Name"
            colon={false}
            rules={[{ required: true }]}
            style={{
              color: themeColorPalettes[theme? theme: 'default'].headerTextColor
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            name={['user', 'last_name']}
            label="Last Name"
            rules={[{ required: true }]}
            colon={false}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            name={['user', 'email']}
            label="Email" 
            rules={[{ type: 'email' }]}
            colon={false}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            name={['user', 'phone']} 
            label="Phome Number" 
            rules={[{ type: 'number' }]}
            colon={false}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item 
            name={['user', 'group']} 
            label="Group"
            colon={false}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            name={['user', 'region']}
            label="Region"
            colon={false}
          >
            <Input />
          </Form.Item>
    
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button 
              htmlType="submit" style={{ 
              backgroundColor: themeColorPalettes[theme].extraColor
            }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  )
}

export default AddUsers
