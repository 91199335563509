/** @jsx jsx */
import React, { useState, useEffect, useContext } from "react";
import { paidBookingsColumnData } from "../../utils/initialData";
import { Table, Descriptions, Typography, Space, Button } from "antd";
import Filters from "../filters/filters.component";
import { toast } from "react-toastify";
import { IThemeProps } from "../yesterday-bookings/yesterday-bookings.component";
import {
  useGetPaidBookings,
  usePaymentTransactionAPI,
  useProfileAxios,
} from "../../utils/customHooks";
import { useNavigate } from "react-router-dom";
import { themeColorPalettes } from "../../utils/utilities";
import { AiOutlineClose } from "react-icons/ai";
import Axios from "axios";
import AppContext from "../../utils/context";

import { jsx } from "@emotion/react";

import {
  actionBtnsCss,
  actionLightBtnsCss,
  paidBookingsCss,
} from "../paid-bookings/paid-bookings.styles";
import { CSVLink } from "react-csv";

const { Title } = Typography;

const AllBookings: React.FC<IThemeProps> = ({ theme }) => {
  const [columns, setColumns] = useState<any>([]);
  const [paidTickets, setPaidTickets] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationType, setPaginationType] = useState("normal");
  const [nextRolePage, setNextRolePage] = useState(1);
  const [paginationMetaData, setPaginationMetaData] = useState(30);
  const [transacBookingId, setTransacBookingId] = useState("");
  const [paymentData, setPaymentData] = useState<any>();

  const navigate = useNavigate();
  const context = useContext(AppContext);
  const { innerWidth, innerHeight } = context.windowSize;
  const { assigned_role: assignedRole } = context.profileInfo;

  const { state: profileState } = useProfileAxios(
    `${process.env.REACT_APP_USER_URL}/users/profile`
  );
  const { state } = useGetPaidBookings(
    `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/ticket/unsent?is_customer_care=is_customer_care&is_operator=${
        assignedRole === "trips_customer_care"
        ? "trips"
        : "admin"
    }&&paid_with_tickets=paid_with_tickets&page=`,
    nextRolePage
  );
  const { state: transactionState } =
    usePaymentTransactionAPI(transacBookingId);

  useEffect(() => {
    if (profileState.status === "fetched") {
      console.log("profile fetched", profileState.data);
    } else if (profileState.status === "fetching") {
      console.log("profile fetching", profileState.data);
    } else if (profileState.status === "idle") {
      console.log("profile idle", profileState.data);
    } else {
      console.log("profile error", profileState.data);
      navigate("/login");
    }
  }, [profileState, navigate]);

  // side effect for all paid bookings retrieval
  // marketplace returns rows of paid bookings

  useEffect(() => {
    // if (profileState.status !== 'fetched') return;
    if (state.status === "fetched") {
      let resBody = state.data.data;
      let parsedArray = resBody.rows.map((ticketHolder: any, idx: Number) => {
        let newTicketInfo = {
          ...ticketHolder,
          key: idx,
          sms: ticketHolder.sms?.message
            ? ticketHolder.sms?.message
            : " - - - - ",
          name: ticketHolder.name
            ? ticketHolder.name
            : ticketHolder.passengers[0].full_name,
          booking_date:  ticketHolder.created_at.split('T')[0].split('-').reverse().join('/')
        };
        return newTicketInfo;
      });
      if (context.profileInfo.assigned_role === "trips") {
        parsedArray = parsedArray.map(((ticket: any, idx: number) => {
          return {
            key: idx,
            booking_date: ticket.booking_date,
            name: ticket.name,
            phone: ticket.phone,
            id: ticket.id,
            trade_id: ticket.trade_id,
            source: ticket.source,
            destination: ticket.destination,
            departure_date: ticket.departure_date,
            total_amount: ticket.total_amount
          }
        }))
        setCsvData(parsedArray)
       
      } else {
      }
      setPaidTickets(parsedArray);
      setPaginationMetaData(resBody.paginationMeta.totalPages);
      setLoading(false);
    } else if (state.status === "fetching") {
      setLoading(true);
    } else if (state.status === "idle") {
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(state.error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 8000,
      });
    }
    return () => {};
  }, [state]);

  // side effect handling 'View Transaction Details'
  useEffect(() => {
    if (!transacBookingId) return;
    if (transactionState.status === "fetched") {
      console.log("transaction fetched", transactionState.data);
      setTransacBookingId("");
      setPaymentData(transactionState.data);
      setLoading(false);
    } else if (transactionState.status === "fetching") {
      console.log("transaction fetching", transactionState.data);
    } else if (transactionState.status === "idle") {
      console.log("transaction idle", transactionState.data);
    } else {
      console.log("transaction error", transactionState.data);
      // history.push('/login')
    }
  }, [transactionState, transacBookingId]);

  // event handlers for action buttons
  const handleResendTicket = async (itemId: any) => {
    setLoading(true);
    console.log("itemId", itemId);
    await Axios.post(
      `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/ticket/resend/${itemId}`, {},
      {
        headers: {
          Authorization: "Bearer " + context.userDetails.data.token
        },
      }
    )
      .then((data) => {
        let resBody = data.data.data;
        console.log(resBody, "ticket sent");
        toast.success(
          `${resBody.message} for booking ID ${resBody.data.id} / phone ${resBody.data.phone}`,
          {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            autoClose: 5000,
          }
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response, "error sending ticket");
        setLoading(false);
        toast.error(error.response.data.detail, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: 5000,
        });
      });
  };

  const handleViewPaymentDetails = (e: any) => {
    console.log("handleViewPaymentDetails", e);
    setLoading(true);
    setTransacBookingId(e.id);
    setLoading(false);
  };

  useEffect(() => {
    let columnInfo = [];
    if (
      context.profileInfo.assigned_role === "trips"
    ) {
      columnInfo = paidBookingsColumnData.filter(
        (item: any, idx: number) => item.key !== "operator"
      );
      columnInfo = columnInfo.filter(
        (item: any, idx: number) => item.key !== "sms"
      );
      columnInfo = columnInfo.filter(
        (item: any, idx: number) => item.key !== "booking_channel"
      );
      columnInfo.unshift({
        title: "Booking Date",
        dataIndex: "booking_date",
        key: "booking_date",
        width: 50,
        fixed: "left",
        ellipsis: true
      });
    } else {
      columnInfo = paidBookingsColumnData;
    }
    let paidBookings = [
      ...columnInfo,
      // {
      //   title: "Action",
      //   key: "action",
      //   width: 80,
      //   fixed: "right",
      //   render: (item: any) => (
      //     <div css={theme === "default" ? actionLightBtnsCss : actionBtnsCss}>
      //       <span
      //         className="action-button"
      //         onClick={() => handleViewPaymentDetails(item)}
      //       >
      //         VIEW PAYMENT DETAILS
      //       </span>
      //     </div>
      //   ),
      // },
    ];
    setColumns([...paidBookings]);
  }, [context.profileInfo.assigned_role, theme]);

  return (
    <div css={paidBookingsCss}>
      <Filters
        theme={theme}
        setLoading={setLoading}
        loading={loading}
        nextPageNumber={nextPageNumber}
        searchQuery={searchQuery}
        setNextRolePage={setNextRolePage}
        setSearchQuery={setSearchQuery}
        setPaidTickets={setPaidTickets}
        setPaginationMetaData={setPaginationMetaData}
        setPaginationType={setPaginationType}
      />
      {paginationMetaData && !paymentData ? (
          <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative'
          }}
        >
        <Table
          columns={columns}
          dataSource={paidTickets}
          loading={loading}
          size="small"
          virtual
          scroll={{
            x: innerWidth * 0.8,
            y:
              innerHeight > 750
                ? innerHeight * 0.7
                : innerHeight > 500
                ? innerHeight * 0.6
                : innerHeight * 0.4,
          }}
          pagination={{
            onChange: (page) => {
              if (paginationType === "search") {
                setNextPageNumber(page);
              } else {
                setNextRolePage(page);
              }
            },
            total: paginationMetaData * 10 - 1,
          }}
        />
        <CSVLink 
          data={csvData}
          filename={"all-bookings.csv"}
          style={{
            backgroundColor:
              theme === "Sierra"
                ? themeColorPalettes[theme].sideBarColor
                : themeColorPalettes[theme].extraColor,
            color:
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor,
            padding: '10px 20px',
            position: 'absolute',
            borderRadius: '5px',
            bottom: '-50px',
            right: '40px'
          }}
          >Export</CSVLink>
        </div>
      ) : paymentData ? (
        <Descriptions
          title={
            <Space
              size={"large"}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Space
                size={"large"}
                style={{
                  flex: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Title
                  type="secondary"
                  level={5}
                  style={{
                    color:
                      theme === "Sierra"
                        ? themeColorPalettes[theme].textColor
                        : themeColorPalettes[theme].headerTextColor,
                  }}
                >
                  PAYMENT DETAILS
                </Title>
                {(context.profileInfo.assigned_role === "trips") ? (
                  <span></span>
                ) : (
                  <Button
                    style={{
                      borderRadius: "10px",
                      color:
                        theme === "Sierra"
                          ? themeColorPalettes[theme].textColor
                          : themeColorPalettes[theme].iconColor,
                      backgroundColor:
                        theme === "Sierra"
                          ? themeColorPalettes[theme].extraColor
                          : themeColorPalettes[theme].sideBarColor,
                    }}
                    onClick={() => handleResendTicket(transacBookingId)}
                  >
                    RESEND TICKET
                  </Button>
                )}
              </Space>
              <Button
                shape="circle"
                onClick={() => setPaymentData("")}
                icon={<AiOutlineClose />}
              ></Button>
            </Space>
          }
          style={{
            maxWidth: "95%",
          }}
          contentStyle={{
            backgroundColor:
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor,
          }}
          labelStyle={{
            color: theme === "Sierra"
            ? themeColorPalettes[theme].textColor
            : themeColorPalettes[theme].headerTextColor,
          }}
          // size='middle'
          bordered
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="Booking ID">
            {paymentData.booking_id}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Channel">
            {paymentData.payment_channel}
          </Descriptions.Item>
          <Descriptions.Item label="Phone No.">
            {paymentData.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Travel Date">
            {paymentData.trip?.initial_trip.departure_date}
          </Descriptions.Item>
          <Descriptions.Item label="Operator">
            {paymentData.operator}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            {paymentData.amount}
          </Descriptions.Item>
          <Descriptions.Item label="Route">
            {paymentData.trip?.initial_trip.route}
          </Descriptions.Item>
          <Descriptions.Item label="Ticket Sent">
            {paymentData.ticket_sent ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Ticket Ref Number">
            {paymentData.ref_number}
          </Descriptions.Item>
          <Descriptions.Item label="Transaction ID">
            {paymentData.transaction_id}
          </Descriptions.Item>
          <Descriptions.Item label="Transaction Message">
            {paymentData.transaction_message}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {paymentData.status}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        "loading..."
      )}
    </div>
  );
};

export default AllBookings;
