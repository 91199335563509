import React from 'react';
import App from './App';

import { createRoot } from 'react-dom/client';
import './index.css';
import { ConfigProvider } from 'antd';

const container = document.getElementById('root');
const root =  createRoot(container!)

root.render(
	<React.StrictMode>
		<ConfigProvider theme={{ hashed: false }}>
			<App />
		</ConfigProvider>
	</React.StrictMode>
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

